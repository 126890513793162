
export default {
  name: 'BrokerageServices',
  fetchOnServer: false,
  data() {
    return {
      services: 0,
    }
  },

  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=BuildServiceCatalogObject&Organization=DFM&Language=${this.$i18n.locale}`,
      })
      let data = await response.json().then((data) => {
        if (this.$i18n.locale === 'ar') {
          return data.Sections.filter((item) => item.Title === 'الأعضاء')
        } else {
          return data.Sections.filter((item) => item.Title === 'Members')
        }
      })
      this.services = data[0]
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    convertDetailLink(link) {
      return link.replace('/products/services-catalog/services-details?id=', '')
    },
  },
}
