
export default {
  i18n: false,
  nuxtI18n: false,
  name: 'IVestorMobileApp',
  layout: 'blank',
  mounted() {
    // // console.log(this.$device)
    if (this.$device.isAndroid) {
      // alert('android')
      setTimeout(() => {
        window.location.href = this.$iVestorGoogleStoreLink
      }, 2000)
    } else if (this.$device.isIos) {
      // alert('ios')
      setTimeout(() => {
        window.location.href = this.$iVestorAppleStoreLink
      }, 2000)
    } else if (this.$device.isDesktop) {
      // alert('desktop')
      window.location.href = '/investing/services/ivestor/'
    }
  },
}
