
export default {
  name: 'ListingReits',
  fetchOnServer: false,

  data() {
    return {
      benefits: this.$t('benefits.blocks'),
    }
  },
}
