
import listedCreditsStatic from '~/static/data/carboncredits.json'
export default {
  fetchOnServer: false,
  data() {
    return {
      listedCreditsStatic,
      initialLoad: true,
    }
  },


}
