
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  components: { vueVimeoPlayer },
  props: {
    videoList: {
      required: true,
    },
  },
  data() {
    return {
      modalRef: 'video' + this._uid,
      playerRef: 'player' + this._uid,
      currentVideoId: '',
    }
  },
  methods: {
    playVideo: function (videoId) {
      this.currentVideoId = videoId
      this.$refs[this.modalRef].showModal = true
      setTimeout(() => {
        this.$refs[this.playerRef].play()
      }, 2000)
    },

    modalClosed() {
      this.$refs[this.playerRef].pause()
    },
  },
}
