import { render, staticRenderFns } from "./_currentIndex.vue?vue&type=template&id=80cf321c&"
import script from "./_currentIndex.vue?vue&type=script&lang=js&"
export * from "./_currentIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_currentIndex.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateRange: require('/vercel/path0/components/ui/DateRange.vue').default,HorizontalLoader: require('/vercel/path0/components/ui/HorizontalLoader.vue').default,BaseCard: require('/vercel/path0/components/ui/BaseCard.vue').default,ChartComponent: require('/vercel/path0/components/ui/ChartComponent.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,PriceChange: require('/vercel/path0/components/micro/PriceChange.vue').default})
