
export default {
  name: 'KeyFigures',

  computed: {
    keyFiguresSrc() {
      return this.$i18n.locale === 'en' ? 'https://tools.euroland.com/tools/ia/?companycode=ae-dfm&v=mob&lang=en-gb' : 'https://tools.euroland.com/tools/ia/?companycode=ae-dfm&v=mob&lang=ar-AE'
    },
  },
}
