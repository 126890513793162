
export default {
  name: 'ListedSecurities',
  data() {
    return {
      tabs: [
        {
          title: this.$t('data-headers.dfmgi'),
          href: '/the-exchange/statistics-reports/historical-data/dfmgi',
        },
        {
          title: this.$t('data-headers.sector-indices'),
          href: '/the-exchange/statistics-reports/historical-data/sector-indices/',
        },
        {
          title: this.$t('data-headers.company-prices'),
          href: '/the-exchange/statistics-reports/historical-data/company-prices/',
        },
        {
          title: this.$t('data-headers.futures'),
          href: '/the-exchange/statistics-reports/historical-data/futures/',
        },
        {
          title: this.$t('data-headers.trade-by-nationality'),
          href: '/the-exchange/statistics-reports/historical-data/trade-by-nationality/',
        },
        {
          title: this.$t('data-headers.trade-by-client-type'),
          href: '/the-exchange/statistics-reports/historical-data/trade-by-client-type/',
        },
      ],
    }
  },
  watch: {
    $route() {
      if (this.$route.name == this.localeRoute('the-exchange-statistics-reports-historical-data').name) {
        this.$router.replace(
          this.localePath({
            name: 'the-exchange-statistics-reports-historical-data-dfmgi',
          })
        )
      }
    },
  },
  mounted() {
    if (this.$route.name == this.localeRoute('the-exchange-statistics-reports-historical-data').name) {
      this.$router.replace(
        this.localePath({
          name: 'the-exchange-statistics-reports-historical-data-dfmgi',
        })
      )
    }
  },
}
