
export default {
  name: 'TopShareholders',
  fetchOnServer: false,

  data() {
    return {
      shareholders: null,
      dataHeaders: ['Shareholder Name', 'Percentage'],
    }
  },
  async fetch() {
    try {
      const shareholdersRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshTopShareholders&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}`,
      })
      this.shareholders = await shareholdersRes.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    associatedGroups() {
      var data = this.shareholders.Item2.filter((item) => item.IsAssociated == true)
      return data[0]
    },
    chartData() {
      var data = []
      this.shareholders.Item2.forEach((item) => {
        data.push([item.Name, parseFloat(item.Percentage)])
      })
      var totalShares = 0
      for (var i = 0; i < data.length; i++) {
        totalShares += data[i][1]
      }

      if (data.length && totalShares < 100) data.push(['Others', 100 - totalShares])

      return data
    },
  },
}
