import { render, staticRenderFns } from "./oil-futures.vue?vue&type=template&id=3d113ac5&"
import script from "./oil-futures.vue?vue&type=script&lang=js&"
export * from "./oil-futures.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/oil-futures-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Foil-futures.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/oil-futures-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fproducts%2Foil-futures.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,PhoneNumber: require('/vercel/path0/components/micro/PhoneNumber.vue').default,EmailAddress: require('/vercel/path0/components/micro/EmailAddress.vue').default,WebAddress: require('/vercel/path0/components/micro/WebAddress.vue').default,ContactCard: require('/vercel/path0/components/blocks/ContactCard.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
