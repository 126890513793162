
// add no-icon attribute to disable icons
export default {
  name: 'SecurityReportsCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'pdf',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    noDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    FileCount() {
      this.$i18n.locale == 'en' ? this.data.EnglishFileCount : this.data.ArabicFileCount
      return this.data.EnglishFileCount
    },
    FileContent() {
      this.$i18n.locale == 'en' ? this.convertDomain(this.data.EnglishFileContent) : this.convertDomain(this.data.ArabicFileContent)
      return this.convertDomain(this.data.EnglishFileContent)
    },
  },
  methods: {
    convertDomain(url) {
      return url.replace('https://www.dfm.ae', 'https://assets.dfm.ae')
    },
  },
}
