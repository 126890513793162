

export default {
  name: 'CareersForm',
  data() {
    return {
      form: {
        FormId: '1846a8f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '4162a8f8-f602-6339-b0d9-ff00009be840',
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        Attachment: '',
        sf_FormHiddenFields: '',
        sf_FormSkipFields: '',
        sf_FormMessage: '',
        sf_FormNotificationEmails: '',
        sf_FormRedirectPage: '',
        AcceptTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      submittingForm: false,
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    onFileChange(event) {
      try {
        let files = event.target.files
        // console.log(files)
        this.form[event.target.name] = files[0]
      } catch (error) {
        console.error(error)
      }
    },
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        const formData = new FormData()

        formData.append('FormId', this.form.FormId),
          formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)),
          formData.append('WidgetId', this.form.WidgetId),
          formData.append('FirstName', this.form.FirstName),
          formData.append('LastName', this.form.LastName),
          formData.append('Email', this.form.Email),
          formData.append('Phone', this.form.Phone),
          formData.append('Position', ''),
          formData.append('Attachment', this.form.Attachment),
          formData.append('sf_FormHiddenFields', this.form.sf_FormHiddenFields),
          formData.append('sf_FormSkipFields', this.form.sf_FormSkipFields),
          formData.append('sf_FormMessage', this.form.sf_FormMessage),
          formData.append('sf_FormNotificationEmails', this.form.sf_FormNotificationEmails),
          formData.append('sf_FormRedirectPage', this.form.sf_FormRedirectPage),
          formData.append('action', this.form.action),
          formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/careersForm', formData)
        if (response.data.success == true) {
          this.showSuccess = true

          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 3000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.FirstName = '',
        this.form.LastName = '',
        this.form.Email = '',
        this.form.Phone = '',
        this.form.Attachment = '',
        this.form.AcceptTerms = false,
        this.$recaptcha.destroy()
    },
  },
}
