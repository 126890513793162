
export default {
  name: 'InvestorRelations',
  computed: {
    analysts() {
      return this.$t('analysts-coverage.analysts.block')
    },
    documents() {
      return this.$t('downloads')
    },
    dfmStock() {
      return this.$store.state.marketwatch.dfmStock
    },
  },
  async activated() {
    await this.$store.dispatch('marketwatch/getDfmStock')
  },
}
