
export default {
  props: {
    ipo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lang() {
      if (this.$i18n.locale == 'en') {
        return 'En'
      } else {
        return 'Ar'
      }
    },
    logo() {
      var l = this.ipo.Claims.filter((claim) => claim.ClaimKey == 'LogoURL')
      return l[0].ClaimKeyNoteEn
    },
  },
}
