
export default {
  name: 'HistoricalFutures',
  fetchOnServer: false,

  data: () => ({
    filterDateRange: [],
    selectedDateRange: [],
    sector: 'All',
    futures: null,
    historicalFutures: [],
    excelFields: {
      Symbol: 'symbol',
      Name: 'name',
      'Expiry Date': 'expiry_date',
      'Contract Size (latest)': 'contract_size',
      'Underlying Security Code': 'underlying_code',
      Trades: 'trade_count',
      'Volume (contracts)': 'volume',
      Value: 'value',
      'Open Interest': 'open_interest',
      'Close (Settlement Price)': 'close',
      'Previous Close': 'prev_close',
      'Last Price (Date)': 'last_trade_price',
      Change: 'change',
      'Change %': 'change_percentage',
      Open: 'open',
      High: 'high',
      Low: 'low',
    },
  }),
  async fetch() {
    try {
      this.loading = true
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchFuturePrices&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Section=all&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()
      this.futures = data
      this.historicalFutures = data.futures
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    modifiedHistoricalFutures() {
      if (!this.historicalFutures.length) return
      return this.historicalFutures.map((item) => {
        let TrendClass = item.change > 0 ? 'increase' : item.change < 0 ? 'decrease' : 'nocrease'
        return {
          ...item,
          ChangeTrendClass: TrendClass,
        }
      })
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
