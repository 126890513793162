import { render, staticRenderFns } from "./MostActiveTable.vue?vue&type=template&id=ad314d72&"
import script from "./MostActiveTable.vue?vue&type=script&lang=js&"
export * from "./MostActiveTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./MostActiveTable.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MostActiveTableData: require('/vercel/path0/components/MostActiveTableData.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default})
