
export default {
  props: {
    management: {
      type: Object,
      required: true,
    },
  },
  methods: {
    secureUrl(url) {
      return url.replace('http://', 'https://')
    },
  },
}
