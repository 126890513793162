
export default {
  name: 'DerivativesTraining',
  layout: 'blank',
  data() {
    return {
      submittingForm: false,
      showSuccess: false,
      form: {
        FormId: 'b5c18ef8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '3ac48ef8-f602-6339-b0d9-ff00009be840',
        Subject: 'Dubai REIT Market',
        TextFieldController: '',
        FullName: '',
        Company: '',
        EmailTextFieldController: '',
        Phone: '',
        action: 'validate_captcha',
      },
    }
  },
  head() {
    return {
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }],
    }
  },
  async mounted() {
    this.$i18n.locale = 'en'
    // console.log('form mounted')
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },

  methods: {
    async submitForm() {
      this.submittingForm = true
      const token = await this.$recaptcha.execute('login')
      // console.log('ReCaptcha token:', token)
      const formData = new FormData()
      formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('Subject', this.form.Subject), formData.append('FullName', this.form.FullName), formData.append('TextFieldController', this.form.TextFieldController), formData.append('EmailTextFieldController', this.form.EmailTextFieldController), formData.append('Phone', this.form.Phone), formData.append('Company', this.form.Company), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

      var response = await this.$axios.post('/eventsformapi', formData)
      if (response.data.success == true) {
        this.showSuccess = true
        this.submittingForm = false
        this.resetForm()
      }
    },
    resetForm() {
      this.form.FullName = ''
      this.form.Company = ''
      this.form.EmailTextFieldController = ''
      this.form.Phone = ''
    },
  },
}
