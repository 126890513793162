

export default {
  name: 'EboardApplicationForm',
  props: {
    position: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        FormId: '223d92f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '998e8ff8-f602-6339-b0d9-ff00009be840',
        FirstName: '',
        LastName: '',
        Gender: '',
        DOB: '',
        NationalID: '',
        CV: '',
        OtherDocuments: [],
        sf_FormHiddenFields: '',
        sf_FormSkipFields: '',
        sf_FormMessage: '',
        sf_FormNotificationEmails: '',
        sf_FormRedirectPage: '',
        AcceptTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      submittingForm: false,
    }
  },
  computed: {
    Nationality() {
      return this.$refs['nationality'].selectedCountry
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    onFileChange(event) {
      try {
        let files = event.target.files
        // console.log(files)
        if (event.target.name === 'OtherDocuments') {
          for (const property in files) {
            // there are many properties in files array
            // we will take only array values properties
            // in which uploaded file exists. so check that property
            // should be a valid number, i.e 0, 1,2
            if (!isNaN(property)) {
              this.form.OtherDocuments.push(event.target.files[property])
            }
          }
        } else {
          this.form[event.target.name] = files[0]
        }
      } catch (error) {
        console.error(error)
      }
    },
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        const formData = new FormData()
        // prepare multi file fields
        for (let i = 0; i < this.form.OtherDocuments.length; i++) {
          const file = this.form.OtherDocuments[i];
          // console.log(file)
          formData.append('OtherDocuments', file);
        }
        formData.append('FormId', this.form.FormId),
          formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)),
          formData.append('WidgetId', this.form.WidgetId),
          formData.append('FirstName', this.form.FirstName),
          formData.append('LastName', this.form.LastName),
          formData.append('DOB', this.form.DOB),
          formData.append('Gender', this.form.Gender),
          formData.append('Nationality', this.Nationality),
          formData.append('Company', this.position.name),
          formData.append('BoardPosition', this.position.position),
          formData.append('NominationCode', this.position.NominationCode),
          formData.append('NationalID', this.form.NationalID),
          formData.append('CV', this.form.CV),
          formData.append('sf_FormHiddenFields', this.form.sf_FormHiddenFields),
          formData.append('sf_FormSkipFields', this.form.sf_FormSkipFields),
          formData.append('sf_FormMessage', this.form.sf_FormMessage),
          formData.append('sf_FormNotificationEmails', this.form.sf_FormNotificationEmails),
          formData.append('sf_FormRedirectPage', this.form.sf_FormRedirectPage),
          formData.append('action', this.form.action),
          formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/eboardApplicationForm', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.$notify({
            title: this.$t('msgs.4'),
            text: this.$t('msgs.5'),
            type: 'success'
          })
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 3000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.FirstName = '',
        this.form.LastName = '',
        this.form.Gender = '',
        this.form.DOB = '',
        this.form.NationalID = '',
        this.form.CV = '',
        this.form.OtherDocuments = [],
        this.form.AcceptTerms = false,
        this.$recaptcha.destroy()
    },
  },
}
