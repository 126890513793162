
export default {
  name: 'CompanyProfilePage',
  data() {
    return {}
  },
  activated() {
    var id = this.$route.query.id
    if (!id) {
      this.$router.push({ path: this.localePath(`/the-exchange/market-information/listed-securities/equities`) })
    } else {
      this.$router.push({ path: this.localePath(`/the-exchange/market-information/company/${this.$route.query.id}`) })
    }
  },
}
