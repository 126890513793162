
import orderBy from 'lodash/orderBy'
import { FadeTransition } from 'vue2-transitions'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import Meta from '~/mixins/meta'

export default {
  name: 'BrokersRanking',
  components: {
    FadeTransition,
    highcharts: Chart,
  },
  mixins: [Meta],
  fetchOnServer: false,
  fetchDelay: 1000,
  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      show: true,
      loading: false,
      rankings: null,
      Chart: null,
      filterKeyword: '',
      filterYear: this.$dayjs().subtract(1, 'month').year(),
      filterMonth: this.$dayjs().subtract(1, 'month').month() + 1,
      years: [],
      months: this.$t('months'),
      sortField: 'rank',
      sortOrder: 'desc',
      excelFields: {
        Period: 'Period',
        Rank: 'Rank',
        Broker: 'BrokerName',
        'Traded Value': 'SumValue',
        '% Value': 'PercValue',
        'Traded Volume': 'SumVolume',
        '% Volume': 'PercVolume',
        Trades: 'SumTrades',
        '% Trades': 'PercTrade',
        'New Accounts': 'NoOfAccounts',
      },
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchBrokerRanking&Year=${this.filterYear}&Month=${this.filterMonth}&Language=${this.$i18n.locale}`,
      })
      let data = await response.json()
      // add an object with the total values of certain fields
      let total = { Rank: ' ', BrokerName: 'Total', SumTrades: 0, SumValue: 0, SumVolume: 0, NoOfAccounts: 0 }
      // add a new object inside data total of all
      data.List.forEach((element) => {
        total.SumTrades += element.SumTrades
        total.SumValue += element.SumValue
        total.SumVolume += element.SumVolume
        total.NoOfAccounts += element.NoOfAccounts
      })
      data.totalValues = total
      this.rankings = data
      this.Chart = data.Chart
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },

  computed: {
    filteredRankings() {
      return this.rankings.List.filter((broker) => {
        return broker.BrokerName.toLowerCase().includes(this.filterKeyword.toLowerCase())
      })
    },

    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        title: {
          text: this.Chart.BrokerName,
          margin: 30,
          style: {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
        subtitle: {
          text: this.$t('ui.chartSubtitle'),
          y: 30,
        },
        legend: true,
        chart: {
          type: 'line',
          height: 600,
          spacingLeft: 40,
          spacingTop: 30,
          alignTicks: false,
          backgroundColor: '#f9f9f9',
          margin: [80, 27, 75, 60],
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#e2e2e2',
          plotBorderWidth: 1,
          style: {
            fontFamily: this.$i18n.locale == 'en' ? 'DM Sans' : 'Almarai',
            fontSize: '16px',
          },
        },
        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
          formatter: function () {
            return `<small>${this.x}</small><br/><div>Rank: ${this.y}<div>`
          },
        },
        xAxis: {
          categories: this.Chart.XCategories,
          max: this.Chart.XCategories.length - 1,
          tickInterval: 6, //two months
          labels: {
            staggerLines: 1,
            rotation: 90,
            y: 10,
            style: {
              color: '#525151',
              fontSize: '12px !important',
            },
          },
        },
        yAxis: {
          reversed: true,
          title: {
            text: this.$t('ui.chartAxisTitle'),
          },
          min: 0,
          labels: {
            formatter: function () {
              if (this.isFirst) return '1'
              return this.value
            },
          },
          minRange: 1,
        },
        series: [
          {
            data: this.Chart.Rates,
            name: this.$t('ui.chartAxisTitle'),
            marker: {
              fillColor: '#FFFFFF',
              lineColor: '#000000',
              lineWidth: 3,
            },
          },
        ],
      }
    },
  },
  mounted() {
    let currentYear = new Date().getFullYear()
    var arr = []
    for (var i = currentYear; i >= 2009; i--) {
      arr.push(i)
    }
    this.years = arr
  },
  methods: {
    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
    sortedRankings(field) {
      this.sortField = field
      this.sortOrder == 'asc' ? (this.sortOrder = 'desc') : (this.sortOrder = 'asc')
      this.rankings.List = orderBy(this.rankings.List, field, this.sortOrder)
    },
  },
}
