
export default {
  name: 'NewsDisclosures',
  data() {
    return {
      dataLoaded: false,
    }
  },
  methods: {
    fetchCompleted() {
      this.dataLoaded = true
    },
  },
}
