

export default {
  name: 'JoinThePilotCarbonCredits',
  data() {
    return {
      form: {
        FormId: 'd790a7f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '0b96a7f8-f602-6339-b0d9-ff00009be840',
        Company: '',
        CompanySize: '',
        Industry: '',
        Fullname: '',
        Email: '',
        Mobile: '',
        Reason: '',
        AcceptTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      showError: false,
      submittingForm: false,
    }
  },

  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        // // console.log('ReCaptcha token:', token)
        const formData = new FormData()
        formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('Company', this.Company), formData.append('CompanySize', this.CompanySize), formData.append('Industry', this.Industry), formData.append('Fullname', this.form.Fullname), formData.append('Email', this.form.Email), formData.append('Mobile', this.form.Mobile), formData.append('Reason', this.form.Reason), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/ivestornotificationform', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 5000)
        } else {
          // this.showError = true
          // setTimeout(() => {
          //   this.showError = false
          //   this.resetForm()
          //   this.formSubmitted()
          // }, 5000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.Company = ''
      this.form.CompanySize = ''
      this.form.Indusry = ''
      this.form.Fullname = ''
      this.form.Email = ''
      this.form.Mobile = ''
      this.form.Reason = ''
      this.form.AcceptTerms = false
      this.$recaptcha.destroy()
    },
  },
}
