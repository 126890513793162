
export default {
  name: 'ExploreAvailableProducts',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
    securityType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      securities: [],
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=FreshSecuritiesLists&securityType=${this.securityType}&Language=${this.$i18n.locale}`,
      })

      this.securities = await response.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    activeSecurities() {
      return this.securities.filter((security) => security.Active)
    },
  },
}
