
export default {
  props: {
    imgsrc: {
      type: String,
      default: 'https://via.placeholder.com/150',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasContentSlot() {
      return !!this.$slots.content
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
