
import Meta from '~/mixins/meta'
export default {
  name: 'RightsIssueSubscription',
  mixins: [Meta],
  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      showBankDetails: false,
    }
  },
  computed: {
    company() {
      return this.$t('bank.company')
    },
    dates() {
      return this.$t('bank.dates')
    },
  },
}
