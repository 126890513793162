
export default {
  props: {
    modalSize: {
      type: Number,
      default: 1280,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    beforeOpen() {
      this.$emit('modal-before-open')
    },
    opened() {
      this.$emit('modal-opened')
    },
    beforeClose() {
      this.$emit('modal-before-close')
    },
    closed() {
      this.$emit('modal-closed')
    },
  },
}
