
export default {
  name: 'EtfNavSummary',
  fetchOnServer: false,
  data() {
    return {
      initialPageLoad: true,
      indicativeNav: null,
      etfNavSummary: null,
      etfNavCount: 0,
      filterDateRange: [],
      itemsToGet: 20,
      currentPage: 1,
      dataHeaders: ['Shareholder Name', 'Percentage'],
    }
  },
  async fetch() {
    try {
      const etfNavSummary = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `command=profileetfnavsummary&period=custom&fromdate=${this.filterDateRange[0]}&todate=${this.filterDateRange[1]}&skip=${(this.currentPage - 1) * this.itemsToGet}&take=${this.itemsToGet}&company=${this.$route.params.SecuritySymbol}&language=${this.$i18n.locale}`,
      })
      let responseData = await etfNavSummary.json()
      this.etfNavSummary = responseData.Result
      this.etfNavCount = responseData.Total
      if (this.initialPageLoad) {
        this.indicativeNav = this.etfNavSummary[0]
      }
      this.initialPageLoad = false
    } catch (error) {
      // console.log(error)
    }
  },

  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    convertData(data) {
      if (data === 'N/A' || data === '0') {
        return data
      } else if (data === '') {
        return 'N/A'
      }
      return `${data}%`
    },
    dateChanged(val) {
      this.filterDateRange = val
      this.$fetch()
    },
    clearDateRange() {
      this.filter.filterDateRange = []
    },
    updatePagination(page) {
      this.currentPage = page
      const myEl = this.$refs.etfNavSummary
      this.$smoothScroll({
        scrollTo: myEl,
      })
      setTimeout(() => {
        this.$fetch()
      }, 500)
    },
  },
}
