import { render, staticRenderFns } from "./dfmgi.vue?vue&type=template&id=7ef26ea7&"
import script from "./dfmgi.vue?vue&type=script&lang=js&"
export * from "./dfmgi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locale/statistics-reports-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fhistorical-data%2Fdfmgi.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "../locale/statistics-reports-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fstatistics-reports%2Fhistorical-data%2Fdfmgi.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateRange: require('/vercel/path0/components/ui/DateRange.vue').default,PriceChange: require('/vercel/path0/components/micro/PriceChange.vue').default,ChartComponent: require('/vercel/path0/components/ui/ChartComponent.vue').default})
