
export default {
  name: 'ShariaClassificationList',
  fetchOnServer: false,
  data() {
    return {
      latestReport: [],
      quarterlyReports: [],
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=shariahlisting&Language=${this.$i18n.locale}`,
      })
      var data = await response.json()
      const firstItem = data.shift()
      this.quarterlyReports = data
      this.latestReport = firstItem
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    checkEntry(arr, val) {
      return arr.includes(val)
    },
    convertQuarter(val) {
      if (this.$i18n.locale == 'en') {
        switch (val) {
          case '1':
            return 'Q1'
          case '2':
            return 'Q2'
          case '3':
            return 'Q3'
          case '4':
            return 'Q4'
        }
        //check current locale
      } else if (this.$i18n.locale == 'ar') {
        switch (val) {
          case '1':
            return 'الربع الأول'
          case '2':
            return 'الربع الثاني'
          case '3':
            return 'الربع الثالث'
          case '4':
            return 'الربع الرابع'
        }
      }
    },
  },
}
