
export default {
  name: 'FlyoutMenu',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    hasRightSlot() {
      return !!this.$slots.right
    },
  },
  watch: {
    $route() {
      this.open = false
    },
  },
  methods: {
    handleClickOutside() {
      if (this.open) {
        if (!this.$device.isMobile) {
          this.open = false
        }
      }
    },
  },
}
