
import DatePicker from 'vue2-datepicker'
import '~/assets/css/datepicker.css'

export default {
  name: 'SingleDatePicker',
  components: {
    DatePicker,
  },
  data: () => ({
    filterDate: '',
  }),
  computed: {
    isLabel() {
      return this.$slots.default
    },
  },
  created() {
    // this.filterDate = this.$dayjs().format('DD/MM/YYYY')
    // this.$parent.filterDate = this.filterDate
  },

  methods: {
    dateChanged() {
      this.$emit('dateChanged', this.filterDate)
    },

    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
  },
}
