
export default {
  name: 'HomePageHeroSingleIpo',
  data() {
    return {
      loaded: false,
      IPOCode: 'alansari',
      ipo: null,
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'Command=getipodetails&IPOCode=alansari',
      })
      var res = await response.json()
      this.ipo = res.Body
    } catch (error) {
      // console.log(error)
    }
  },
}
