
export default {
  name: 'SectorIndices',
  fetchOnServer: false,

  data: () => ({
    filterDateRange: [],
    selectedDateRange: [],
    selectedSector: 'All',
    sectorIndices: [],
    sectorsData: [],
    excelFields: {
      Index: 'name',
      'Open Price': 'open_price',
      High: 'high',
      'High (Date)': 'high_date',
      Low: 'low',
      'Low (Date)': 'low_date',
      'Current Index': 'current_index',
      Change: 'change',
      'Change %': {
        field: 'change_percentage',
        callback: (value) => {
          return `${value}%`
        },
      },
    },
  }),
  async fetch() {
    try {
      this.loading = true
      const indicesRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchSectorIndices&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Sector=${this.selectedSector}&Language=${this.$i18n.locale}&type=json`,
      })
      const sectorsRes = await fetch(`${this.$config.apiSfUrl}/sectors?sf_culture=${this.$i18n.locale}`)
      this.sectorIndices = await indicesRes.json()
      var sectorsData = await sectorsRes.json()
      this.sectorsData = sectorsData.value.filter((item) => !item.Hide)
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    modifiedSectorIndices() {
      if (!this.sectorIndices.length) return
      return this.sectorIndices.map((item) => {
        let TrendClass = item.Change > 0 ? 'increase' : item.Change < 0 ? 'decrease' : 'nocrease'
        return {
          ...item,
          ChangeTrendClass: TrendClass,
        }
      })
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
