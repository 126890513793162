import { render, staticRenderFns } from "./reports-presentations.vue?vue&type=template&id=19ecb39a&"
import script from "./reports-presentations.vue?vue&type=script&lang=js&"
export * from "./reports-presentations.vue?vue&type=script&lang=js&"
import style0 from "./reports-presentations.vue?vue&type=style&index=0&id=19ecb39a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locale/reports-presentations-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Finvestor-relations%2Freports-presentations.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "../locale/reports-presentations-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Finvestor-relations%2Freports-presentations.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,ReportsPresentationsCard: require('/vercel/path0/components/ReportsPresentationsCard.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default})
