
export default {
  name: 'ProductsEquity',
  fetchOnServer: false,

  data() {
    return {
      features: this.$t('features'),
    }
  },
}
