
export default {
    props: {
        type: {
            type: String,
            default: 'href',
        },
        href: {
            type: String,
            default: '#',
        },
        inverse: {
            type: Boolean,
            default: false,
        },
    },
}
