import { render, staticRenderFns } from "./join-the-pilot-form.vue?vue&type=template&id=d7bed6f4&"
import script from "./join-the-pilot-form.vue?vue&type=script&lang=js&"
export * from "./join-the-pilot-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
