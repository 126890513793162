
import Meta from '~/mixins/meta'
export default {
  name: 'InvestorKYC',
  mixins: [Meta],
  data() {
    return {
      video: this.$t('steps.video'),
      enhancements: Object.values(this.$t('enhancements.blocks')),
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
    }
  },
  computed: {
    features() {
      return Object.values(this.$t('features.blocks'))
    },
    availableLocales() {
      return this.$i18n.locales
    },
    currentLocale() {
      return this.$i18n.locales.filter((i) => i.code == this.$i18n.locale)
    },
    showModal() {
      if (this.$route.matched.length > 1) {
        return true
      }
      return false
    }
  },
}
