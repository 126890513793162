
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import stockInit from 'highcharts/modules/stock'

if (typeof Highcharts === 'object') {
  stockInit(Highcharts)
}

export default {
  fetchOnServer: false,
  components: {
    highcharts: Chart,
  },

  props: {
    chartTitle: {
      type: String,
      default: '',
    },
    axisTitle: {
      type: String,
      default: 'Value',
    },
    navigator: {
      type: Boolean,
      default: false,
    },
    chartHeight: {
      type: Number,
      default: 342,
    },
  },
  data() {
    return {
      loading: true,
      loadPlaceholder: false,
      chartData: [],
      chartFeedData: [],
      timer: '',
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          height: this.chartHeight,
          alignTicks: false,
          // backgroundColor: '#eee',
          margin: this.$i18n.locale == 'en' ? [10, 0, 30, 40] : [10, 50, 30, 0],
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#eeeeee',
          plotBorderWidth: 1,
          style: {
            fontFamily: 'DM Sans, Helvetica , arial, _sans',
            fontSize: '14px',
          },
        },
        title: false,
        loading: {
          style: {
            backgroundColor: 'blue',
          },
          labelStyle: {
            color: 'white',
          },
        },
        rangeSelector: false,
        navigator: {
          enabled: false,
        },

        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
        },
        scrollbar: {
          enabled: false,
        },

        xAxis: [
          {
            // type: 'datetime',
            reversed: this.$i18n.locale == 'en' ? false : true,
            dateTimeLabelFormats: {
              minute: '%H:%M',
              hour: '%H:%M',
            },
            ordinal: false,
            showFirstLabel: true,
            lineColor: '#0095C5',
            labels: {
              format: '{value:%l:%M %p }',
              style: {
                fontSize: '13',
              },
            },
          },
        ],
        yAxis: [
          {
            // alternateGridColor: '#f9f9f9',
            y: 20,
            opposite: this.$i18n.locale == 'en' ? false : true,
            title: {
              text: '',
              style: {
                fontWeight: 'bold',
                fontSize: '14',
                textTransform: 'uppercase',
              },
            },
            lineWidth: 1,
            labels: {
              align: this.$i18n.locale == 'en' ? 'right' : 'left',
              style: {
                fontSize: '13',
              },
              x: this.$i18n.locale == 'en' ? -5 : 10,
              y: 2,
            },
          },
        ],

        series: [
          {
            name: 'DFMGI',
            data: [],
          },
        ],
      },
    }
  },
  async fetch() {
    this.loading = true
    const res = await fetch(`${this.$config.apiMwUrl}/indices`)
    this.chartData = await res.json()
    this.processChartData()
    this.loading = false
  },
  computed: {
    endDate() {
      return this.$dayjs().format('YYYY-MM-DD')
    },
    startDate() {
      return this.$dayjs().subtract(12, 'month').format('YYYY-MM-DD')
      // return "01-01-2007";
    },
  },
  activated() {
    // this is called when the component is activated
    this.timer = setInterval(() => {
      this.$fetch()
      // // console.log('fetch called')
    }, 60000)
  },

  deactivated() {
    // this is called when the component is deactivated
    // // console.log('dactivated')
    clearInterval(this.timer)
  },
  methods: {
    processChartData() {
      if (!this.chartData.length) {
        this.loadPlaceholder = true
        return
      }
      let data = this.chartData
      let tmp = []
      data.forEach((element) => {
        const plotTime = new Date(element.id).getTime()
        const fourHoursInMilliseconds = 4 * 60 * 60 * 1000 // 4 hours in milliseconds
        const updatedPlotTime = plotTime + fourHoursInMilliseconds
        tmp.unshift([
          // // console.log(this.$dayjs(element.id).format('HH:mm')), // the date
          updatedPlotTime, // the date
          element.value, // close
        ])
      })
      this.chartOptions.series[0].data = tmp
    },
  },
  cancelAutoUpdate() {
    clearInterval(this.timer)
  },
}
