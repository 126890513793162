
import listedCredits from '~/static/data/carboncredits.json'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import Tab from '../Tab.vue'
export default {
  components: { Tab },
  data() {
    return {
      loading: false,
      isFiltersOpen: false,
      itemsToGet: 10,
      currentPage: 1,
      resultCount: 0,
      selectedNames: [],
      selectedTypes: [],
      selectedCountries: [],
      selectedVintages: [],
      selectedVerifiers: [],
      vintageEntryMin: '',
      vintageEntryMax: '',
      vintageMin: 0,
      vintageMax: 9999,
      status: '',
      sortField: 'Name',
      sortDirection: 'asc',
      filteredFields: [],
      listedCredits,
    }
  },
  computed: {
    sortedCredits() {
      return sortBy(this.filteredCredits, this.sortField)
    },
    totalCredits: function () {
      return this.filteredCredits.length
    },
    totalPages: function () {
      return Math.ceil(this.totalCredits / this.itemsToGet)
    },
    filteredCredits() {
      this.filteredFields = []
      var data = this.listedCredits
      if (this.vintageEntryMin || this.vintageEntryMax) {
        data = data.filter((credit) => {
          return credit.Vintage >= this.vintageMin && credit.Vintage <= this.vintageMax
        })
        this.filteredFields.push('Vintage')
      }
      if (this.selectedNames.length) {
        data = data.filter((credit) => {
          return this.selectedNames.includes(credit.Name)
        })
        this.filteredFields.push('Name')
      }
      if (this.selectedTypes.length) {
        data = data.filter((credit) => {
          return this.selectedTypes.includes(credit.Type)
        })
        this.filteredFields.push('Type')
      }
      if (this.selectedCountries.length) {
        data = data.filter((credit) => {
          return this.selectedCountries.includes(credit.Country)
        })
        this.filteredFields.push('Country')
      }
      if (this.selectedVintages.length) {
        data = data.filter((credit) => {
          return this.selectedVintages.includes(credit.Vintage)
        })
        this.filteredFields.push('Vintage')
      }
      if (this.selectedVerifiers.length) {
        data = data.filter((credit) => {
          return this.selectedVerifiers.includes(credit.Verifier)
        })
        this.filteredFields.push('Verifier')
      }

      function compare(a, b) {
        if (a.name > b.name) return -1
        if (a.name < b.name) return 1
        return 0
      }
      return orderBy(data, this.sortField, this.sortDirection)
    },
    filteredActiveCredits() {
      return this.filteredCredits.filter((credit) => {
        return credit.Status === 'Active'
      })
    },
    filteredRetiredCredits() {
      return this.filteredCredits.filter((credit) => {
        return credit.Status === 'Retired'
      })
    },
    pagedCredits() {
      return this.filteredCredits.slice((this.currentPage - 1) * this.itemsToGet, this.currentPage * this.itemsToGet)
    },
    pagedActiveCredits() {
      return this.filteredActiveCredits.slice((this.currentPage - 1) * this.itemsToGet, this.currentPage * this.itemsToGet)
    },
    pagedRetiredCredits() {
      return this.filteredRetiredCredits.slice((this.currentPage - 1) * this.itemsToGet, this.currentPage * this.itemsToGet)
    },
    names() {
      return [...new Set(this.listedCredits.map((credit) => credit.Name))]
    },
    types() {
      return [...new Set(this.listedCredits.map((credit) => credit.Type))]
    },
    countries() {
      return [...new Set(this.listedCredits.map((credit) => credit.Country))]
    },
    vintages() {
      return [...new Set(this.listedCredits.map((credit) => credit.Vintage))]
    },
    verifiers() {
      return [...new Set(this.listedCredits.map((credit) => credit.Verifier))]
    },
  },
  mounted() {
    this.vintageMin = Math.min(...this.vintages)
    this.vintageMax = Math.max(...this.vintages)
  },
  methods: {
    tabChanged() {
      this.currentPage = 1
    },
    applyVintageYear() {
      console.log(this.vintageEntryMin, this.vintageEntryMax)
      this.vintageEntryMin ? (this.vintageMin = this.vintageEntryMin) : (this.vintageMin = Math.min(...this.vintages))
      this.vintageEntryMax ? (this.vintageMax = this.vintageEntryMax) : (this.vintageMax = Math.max(...this.vintages))
      this.$refs.vintageDropdown.isOpen = false
    },
    clearVintageYear() {
      this.vintageEntryMin = null
      this.vintageEntryMax = null
      this.vintageMin = Math.min(...this.vintages)
      this.vintageMax = Math.max(...this.vintages)
      this.$refs.vintageDropdown.isOpen = false
    },
    clearSearch() {
      this.vintageEntryMin = ''
      this.vintageEntryMax = ''
      this.applyVintageYear()
      this.$refs.nameSelect.deleteSelectedData()
      this.$refs.typeSelect.deleteSelectedData()
      this.$refs.countrySelect.deleteSelectedData()
      this.$refs.verifierSelect.deleteSelectedData()
      this.status = ''
      this.searchTerm = ''
      this.filteredFields.length = 0
    },
    getValues(e, field) {
      this[field] = e
    },
    flipSortDirection(col) {
      this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc'
      this.sortField = col
    },
    updatePagination(page) {
      this.currentPage = page
      const myEl = this.$refs.carbonCredits
      this.$smoothScroll({
        scrollTo: myEl,
      })
    },
  },
}
