
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    type: {
      type: String,
      default: 'disclosure',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: 'pdf',
    },
    href: {
      type: String,
      default: '#',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    noDetail: {
      type: Boolean,
      default: false,
    },
  },
}
