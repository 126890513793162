
export default {
  name: 'MediaKit',
  fetchOnServer: false,
  data() {
    return {
      mediaKit: [],
    }
  },
  async fetch() {
    try {
      const mediaKit = await this.$axios.get(`${this.$config.apiSfUrl}/communicationpacks?$filter=communicationpackssections/any(x:x%20eq%200ADC6DF8-F602-6339-B0D9-FF00009BE840)&$select=*&$expand=Background,Icon,Download&sf_culture=${this.$i18n.locale}`)
      this.mediaKit = mediaKit.data.value
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    downloadMedia(url) {
      let newUrl = url.replace('https://www.dfm.ae', 'https://assets.dfm.ae').replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
      // console.log(newUrl)
      window.open(newUrl, '_blank')
    },
    convertDomain(url) {
      return url.replace('https://www.dfm.ae', 'https://assets.dfm.ae').replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
    },
  },
}
