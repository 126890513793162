
export default {
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/dfm-image-01041_trcqs1',
    },
    verticalAlign: {
      type: String,
      default: 'object-center',
    },
    crop: {
      type: String,
      default: 'lfill',
    },
    blendMode: {
      type: String,
      default: 'mix-blend-overlay',
    },
  },
}
