
export default {
  name: 'StockPriceIcon',
  props: {
    status: {
      type: String,
      default: 'up',
    },
  },
}
