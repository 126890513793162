
import Meta from '~/mixins/meta'
import { Glide, GlideSlide } from 'vue-glide-js'

export default {

  name: 'CarbonCredits',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  fetchOnServer: false,
  mixins: [Meta],

  data() {
    return {
      selectedProject: null,
      selectedProjectContractSpecs: [],
      showSpecsModal: true,
      breakpoints: {
        800: {
          gap: 24,
          perView: 2.2,
        },
        600: {
          gap: 10,
          perView: 1.1,
        },
      },
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
    }
  },
  computed: {
    projects() {
      return this.$t('discover.blocks')
    },

  },
  mounted() {
    this.$nextTick(() => {
      this.selectedProject = this.$t('discover.blocks[0].specifications')
    })
  },
  methods: {
    openSpecifications(item) {
      this.selectedProject = item
      this.$refs.contractSpecsModal.showModal = true
      // this.showSpecsModal = true
    },
  },
}
