
// add no-icon attribute to disable icons
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'pdf',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    noDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    document(url) {
      url = url.replace('https://www.dfm.ae', 'https://assets.dfm.ae')
      return url
    },
  },
}
