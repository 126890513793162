
export default {
  name: 'HomePage',
  fetchOnServer: false,
  data() {
    return {
      engagewithus: this.$t('engagewithus.blocks'),
      loading: false,
      dfmDisclosures: [],
      ndDisclosures: [],
      marketAnnouncements: [],
      generalNews: [],
      stockData: [],
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'Command=getstockslite',
      })
      this.stockData = await response.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    isProduction() {
      return process.env.NODE_ENV === 'production'
    },
  },
}
