
import Popper from 'vue-popperjs'
import '~/assets/css/popper.css'

export default {
  name: 'ForeignInvestments',
  fetchOnServer: false,
  components: { popper: Popper },
  data() {
    return {
      popperOptions: {
        placement: 'top',
        modifiers: { offset: { offset: '0,10px' } },
      },
      foreignInvestments: null,
      dataHeaders: ['Shareholder Name', 'Percentage'],
    }
  },
  async fetch() {
    try {
      const foreignInvestRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshForeignInvestment&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}`,
      })
      this.foreignInvestments = await foreignInvestRes.json()
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    convertData(data) {
      if (data === 'N/A' || data === '0') {
        return data
      } else if (data === '' || data === null) {
        return 'N/A'
      }
      return `${data}%`
    },
  },
}
