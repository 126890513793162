
export default {
  name: 'GeneralNews',
  fetchOnServer: false,
  data: () => ({
    generalNews: [],
  }),
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshHomePageAnnouncements&Type=general_news&Language=${this.$i18n.locale}`,
      })

      var data = await response.json()
      this.generalNews = data
    } catch (error) {
      // console.log(error)
    }
  },
}
