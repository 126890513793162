
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  name: 'SukukBonds',
  fetchOnServer: false,
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data() {
    return {
      features: this.$t('s2.features'),
      sliderbreakpoints: {
        1024: {
          gap: 24,
          perView: 1.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
}
