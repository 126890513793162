
import BaseButton from '~/components/ui/BaseButton.vue'
export default {
  name: 'DerivativesProduct',
  components: { BaseButton },
  fetchOnServer: false,

  data() {
    return {
      benefits: this.$t('benefits.blocks'),
      sliderbreakpoints: {
        1024: {
          gap: 24,
          perView: 1.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
  methods: {
    openModal(ref) {
      this.$refs[ref].showModal = true
    },
    closeModalAndGoto(ref, link) {
      this.$refs[ref].showModal = false
      this.$router.push(this.localePath(link))
    },
  },
}
