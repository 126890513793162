
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart,
  },

  props: {
    chartData: {
      default: null,
    },
    seriesName: {
      default: 'Series',
    },

    chartTitle: {
      type: String,
      default: 'Chart',
    },
    axisTitle: {
      type: String,
      default: 'Value',
    },
    navigator: {
      type: Boolean,
      default: false,
    },
    chartHeight: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      chartOptionsSpline: {
        credits: {
          enabled: false,
        },
        chart: {
          height: this.chartHeight,
          spacingLeft: 40,
          spacingTop: 30,
          alignTicks: false,
          backgroundColor: '#f9f9f9',
          marginRight: 30,
          marginBottom: 80,
          marginLeft: 40,
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#eeeeee',
          plotBorderWidth: 1,
          style: {
            fontFamily: 'Helvetica , arial, _sans',
            fontSize: '16px',
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14',
              },
            },
            enableMouseTracking: true,
          },
        },
        title: {
          text: this.chartTitle,
          margin: 30,
          style: {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
        loading: {
          style: {
            backgroundColor: 'blue',
          },
          labelStyle: {
            color: 'white',
          },
        },

        navigator: {
          enabled: this.navigator,
          height: 60,
          maskFill: 'rgba(0, 149, 197, 0.2)',
          // maskInside: false,
          fillOpacity: 0.5,
          handles: {
            lineWidth: 1,
            width: 10,
            height: 17,
            backgroundColor: '#0095C5',
            borderColor: 'white',
          },
        },

        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
        },
        scrollbar: {
          enabled: false,
        },

        xAxis: [
          {
            lineColor: '#0095C5',
            labels: {
              style: {
                fontSize: '14',
              },
            },
          },
        ],
        yAxis: [
          {
            // alternateGridColor: '#f9f9f9',

            title: {
              text: this.axisTitle,
              style: {
                fontWeight: 'bold',
                fontSize: '14',
                textTransform: 'uppercase',
              },
            },
            lineWidth: 1,
            labels: {
              style: {
                fontSize: '14',
              },
            },
          },
        ],

        series: [
          {
            type: 'line',
            name: this.seriesName,
            data: this.chartData,
          },
        ],
      },
    }
  },
  computed: {
    endDate() {
      return this.$dayjs().format('YYYY-MM-DD')
    },
    startDate() {
      return this.$dayjs().subtract(12, 'month').format('YYYY-MM-DD')
      // return "01-01-2007";
    },
  },

  mounted() {
    // this.processChartData()
  },
  methods: {
    processChartData() {
      let data = this.chartData
      let tmp = []
      data.forEach((element) => {
        tmp.push([
          Date.parse(element.date), // the date
          parseFloat(element.currentIndex), // close
        ])
      })
      this.chartOptionsSpline.series[0].data = tmp
    },
  },
}
