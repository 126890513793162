
export default {
  name: 'MostActiveTable',
  fetchOnServer: false,
  data() {
    return {
      activeDataTimer: '',
      stockData: [],
    }
  },
  async fetch() {
    try {
      const res = await fetch(`${this.$config.apiMwUrl}/stocks`)
      let data = await res.json()
      if (data.length) {
        this.stockData = data
      }
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    mostActiveByValue() {
      if (this.stockData.length === 0) return []
      return this.stockData.slice().sort((a, b) => b.totalvalue - a.totalvalue)
    },
    mostActiveByVolume() {
      return this.stockData.slice().sort((a, b) => b.totalvolume - a.totalvolume)
    },
    gainers() {
      return this.stockData.slice().sort((a, b) => b.changepercentage - a.changepercentage)
    },
    losers() {
      return this.stockData.slice().sort((a, b) => a.changepercentage - b.changepercentage)
    },
  },

  mounted() {
    // this is called when the component is activated
    this.activeDataTimer = setInterval(() => {
      this.$fetch()
      // // console.log('active data', this.$dayjs().format('mm:ss'))
    }, 30000)
  },
  activated() {
    // this is called when the component is activated
    this.activeDataTimer = setInterval(() => {
      this.$fetch()
      // console.log('active data', this.$dayjs().format('mm:ss'))
    }, 30000)
  },

  deactivated() {
    // this is called when the component is deactivated
    // // console.log('dactivated')
    clearInterval(this.activeDataTimer)
  },
  unmounted() {
    // this is called when the component is deactivated
    // // console.log('dactivated')
    clearInterval(this.activeDataTimer)
  },
}
