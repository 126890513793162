
export default {
  props: {
    placeholder: {
      type: String,
      default: function () {
        return this.$t('commons.filter-by-keyword')
      },
    },
  },
  data() {
    return {
      filterKeyword: '',
    }
  },

  watch: {
    filterKeyword() {
      this.$emit('search', this.filterKeyword)
    },
  },
  mounted() {
    if (this.$attrs.value) {
      this.filterKeyword = this.$attrs.value
    }
  },
  methods: {
    add() {
      this.$emit('search', this.filterKeyword)
    },
    clearInput() {
      this.filterKeyword = ''
      // this.$emit('search', this.filterKeyword)
    },
  },
}
