
export default {
  name: 'MarketStatus',
  computed: {
    statusData() {
      return this.$store.state.marketwatch.dfmgi
    },

  },
  methods: {
    getStatus() {
      if (this.statusData) {
        switch (this.statusData.mode) {
          case 0:
            return this.$t('global.marketstatus[0]')
          case 1:
            return this.$t('global.marketstatus[1]')
          case 2:
            return this.$t('global.marketstatus[2]')
          case 3:
            return this.$t('global.marketstatus[3]')
          case 4:
            return this.$t('global.marketstatus[3]')
          // return 'preclosematch'
          case 5:
            return this.$t('global.marketstatus[3]')
          // return 'tradeatlast'
          default:
            return this.$t('global.marketstatus[0]')
        }
      }
    },
    getStatusClass() {
      if (this.statusData) {
        switch (this.statusData.mode) {
          case 0:
            return 'closed'
          case 1:
            return 'open'
          case 2:
            return 'preopen'
          case 3:
            return 'preclose'
          case 4:
            return 'preclose'
          // return 'preclosematch'
          case 5:
            return 'preclose'
          // return 'tradeatlast'
          default:
            return 'closed'
        }
      }

    },
  }
}
