import { render, staticRenderFns } from "./DisclosureCard.vue?vue&type=template&id=c27131cc&"
import script from "./DisclosureCard.vue?vue&type=script&lang=js&"
export * from "./DisclosureCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomDropdown: require('/vercel/path0/components/ui/CustomDropdown.vue').default})
