
export default {
  name: 'DerivativesTraining',
  layout: 'blank',
  data() {
    return {
      submittingForm: false,
      showSuccess: false,
      formEndPoint: 'https://assets.dfm.ae/forms---ajax/derivatives-training/AjaxSubmit',
      form: {
        FormId: '5abd8ef8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '34c08ef8-f602-6339-b0d9-ff00009be840',
        FullName: '',
        Company: '',
        EmailTextFieldController: '',
        Phone: '',
      },
    }
  },
  head() {
    return {
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }],
    }
  },
  mounted() {
    this.$i18n.locale = 'en'
  },
  methods: {
    async submitForm() {
      this.submittingForm = true
      const formData = new FormData()
      formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', this.form.MarketoSettings), formData.append('WidgetId', this.form.WidgetId), formData.append('FullName', this.form.FullName), formData.append('Company', this.form.Company), formData.append('EmailTextFieldController', this.form.EmailTextFieldController), formData.append('Phone', this.form.Phone)

      var response = await this.$axios.post('/derivativesformapi', formData)
      if (response.data.success == true) {
        this.showSuccess = true
        this.submittingForm = false
        setTimeout(() => {
          this.showSuccess = false
          this.resetForm()
        }, 3000)
      }
    },
    resetForm() {
      this.form.FullName = ''
      this.form.Company = ''
      this.form.EmailTextFieldController = ''
      this.form.Phone = ''
    },
  },
}
