
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      default: 'visuals/dfm-visual-6_b308gh.jpg',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: null,
    },
    decorator: {
      type: Boolean,
      default: false,
    },
    decoratorColor: {
      type: String,
      default: 'text-brand-green',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    imageCrop: {
      type: String,
      default: 'lfill',
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
  },
}
