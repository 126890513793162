
export default {
  name: 'SystemProviders',
  fetchOnServer: false,

  data() {
    return {
      providers: null,
      altLogos: null,
    }
  },
  async fetch() {
    let providers = await this.$axios.get(`${this.$config.apiSfUrl}/internationalstrategicvendors`, {
      params: {
        $expand: 'LogoNew',
        sf_culture: this.$i18n.locale,
      },
    })
    let logos = await this.$axios.get(`${this.$config.apiSfUrl}/internationalstrategicvendors`, {
      params: {
        $expand: 'LogoNew',
        sf_culture: 'en',
        $select: 'Id,LogoNew',
      },
    })

    providers.data.value.map((provider) => {
      let altLogo = logos.data.value.find((logo) => logo.Id === provider.Id)
      if (altLogo) {
        provider.LogoAlt = altLogo.LogoNew
      }
    })

    this.providers = providers.data.value
    this.altLogos = logos.data.value
  },
}
