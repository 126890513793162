
export default {
  name: 'DefaultLayout',
  head() {
    return this.$nuxtI18nHead({ addDirAttribute: true, addSeoAttributes: true })
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
  },
  async mounted() {
    await this.$store.dispatch('latestContent/getLatestPress')
    await this.$store.dispatch('marketwatch/getdfmgi')
    setInterval(() => {
      this.$store.dispatch('marketwatch/getdfmgi')
    }, 30000)
  },
}
