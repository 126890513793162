
export default {
    head: {
        bodyAttrs: {
            dir: 'ltr',
        },
        script: [
            // { src: '/head.js' },
            // Supported since 1.0
            { src: '/js/jquery-3.6.0.min.js', body: true },
            { src: '/js/vendor.min.js', body: true },
            { src: '/js/scripts.js', body: true },
            // { src: '/defer.js', defer: '' },
        ],
    },
}
