
export default {
  name: 'BlankLayout',
  head() {
    return this.$nuxtI18nHead({ addDirAttribute: true, addSeoAttributes: true })
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
  },
}
