import { render, staticRenderFns } from "./ivestor.vue?vue&type=template&id=7cf1da45&"
import script from "./ivestor.vue?vue&type=script&lang=js&"
export * from "./ivestor.vue?vue&type=script&lang=js&"
import style0 from "./ivestor.vue?vue&type=style&index=0&id=7cf1da45&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/ivestor-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fivestor.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/ivestor-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fivestor.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Decorator: require('/vercel/path0/components/ui/decorator.vue').default,IvestorAppStoreLinks: require('/vercel/path0/components/blocks/ivestorAppStoreLinks.vue').default,PageHeroLarge: require('/vercel/path0/components/PageHeroLarge.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,CardWithImageBg: require('/vercel/path0/components/ui/CardWithImageBg.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,FaqsAccordionMulti: require('/vercel/path0/components/datablocks/FaqsAccordionMulti.vue').default})
