
export default {
  name: 'Reports',
  fetchOnServer: false,
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      integratedReports: [],
    }
  },
  async fetch() {
    try {
      const response = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah?types=integrated_reports`, {
        params: {
          symbol: this.securitySymbol,
          lang: this.$i18n.locale,
          cms_resources: true,
        },
      })
      this.integratedReports = response.data.root
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    securitySymbol() {
      if (this.companyInfo.IsLinked) {
        return this.companyInfo.LinkedSymbol
      }
      return this.$route.params.SecuritySymbol
    },
  },
}
