
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  name: 'DfmBrokersMembers',
  fetchOnServer: false,
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data() {
    return {
      brokers: null,
      selectedBroker: null,
      keyword: '',
      sliderbreakpoints: {
        1024: {
          gap: 24,
          perView: 1.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
  async fetch() {
    let brokers = await fetch(`${this.$config.apiSfUrl}/brokers?$filter=MarketMaker eq true&$expand=BrokerLogoNew&$orderby=BrokerName&sf_culture=${this.$i18n.locale}`).then((res) => res.json())

    let altLogos = await fetch(`${this.$config.apiSfUrl}/brokers?$filter=MarketMaker eq true&$expand=BrokerLogoNew&$orderBy=BrokerName&$select=Id,BrokerLogoNew&sf_culture=en`).then((res) => res.json())

    //convert services code to text
    brokers.value.forEach((broker) => {
      let membershipBinary = (broker.Services >>> 0).toString(2)
      if (this.$i18n.locale == 'en') {
        var servicesArr = this.$servicesArrEn
      } else {
        var servicesArr = this.$servicesArrAr
      }
      let reverseBinary = membershipBinary.split('').reverse().join('')
      var membershipLabel = []

      for (var i = 0; i <= membershipBinary.length; i++) {
        if (membershipBinary[i] == '1' && i == membershipBinary.length - 1) {
          membershipLabel.push(servicesArr[i])
        } else if (membershipBinary[i] == '1') {
          membershipLabel.push(servicesArr[i])
        }
      }
      broker.offeredServices = membershipLabel
    })

    brokers.value.map((broker) => {
      let altLogo = altLogos.value.find((altLogo) => altLogo.Id === broker.Id)
      if (altLogo) {
        broker.LogoAlt = altLogo.BrokerLogoNew
      }
    })

    this.brokers = brokers.value
    this.altLogos = altLogos.value
    this.selectedBroker = brokers.value[0]
  },
  computed: {
    sortedBrokers: function () {
      function compare(a, b) {
        if (a.BrokerName < b.BrokerName) return -1
        if (a.BrokerName > b.BrokerName) return 1
        return 0
      }

      return this.brokers.sort(compare)
    },
    filteredBrokers: function () {
      return this.brokers.filter((broker) => {
        return broker.BrokerName.toLowerCase().includes(this.keyword)
      })
    },
  },
  methods: {
    openModal(broker) {
      this.selectedBroker = broker
      this.$refs.brokerModal.showModal = true
    },
    //arabic language api dont have arabic logos, so we switch to english logos if the array dont have values
    verifyLogo(broker) {
      if (!broker) return
      if (broker.BrokerLogoNew.length) {
        return this.$config.assetsDomain + broker.BrokerLogoNew[0].ItemDefaultUrl + broker.BrokerLogoNew[0].Extension
      } else {
        if (broker.LogoAlt) {
          return this.$config.assetsDomain + broker.LogoAlt[0].ItemDefaultUrl + broker.LogoAlt[0].Extension
        }
      }
    },
    convertDate(date) {
      const d = new Date(date)
      return d.toLocaleDateString()
    },
    currentStatus(status) {
      if (status === '1') {
        return this.$t('commons.active')
      }
      if (status === '2') {
        return this.$t('commons.inactive')
      }
      return '--'
    },
  },
}
