
export default {
  props: {
    features: {
      type: [Object, Array],
      required: true,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
}
