
export default {
  props: {
    nuxtlink: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },

    buttonGhost: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'arrow',
    },
    link: {
      type: String,
      default: '/',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
}
