import { render, staticRenderFns } from "./positions.vue?vue&type=template&id=4c75e41a&scoped=true&"
import script from "./positions.vue?vue&type=script&lang=js&"
export * from "./positions.vue?vue&type=script&lang=js&"
import style0 from "./positions.vue?vue&type=style&index=0&id=4c75e41a&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c75e41a",
  null
  
)

/* custom blocks */
import block0 from "./locale/eboard-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fnews-disclosures%2Fe-board%2Fpositions.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/eboard-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fnews-disclosures%2Fe-board%2Fpositions.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EboardApplicationForm: require('/vercel/path0/components/forms/EboardApplicationForm.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
