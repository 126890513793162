
import Meta from '~/mixins/meta'

export default {
  name: 'NewsMedia',
  mixins: [Meta],
  fetchOnServer: false,
  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },

      tabs: [
        {
          title: this.$t('tabs.0.title'),
          href: this.$t('tabs.0.href'),
        },
        {
          title: this.$t('tabs.1.title'),
          href: this.$t('tabs.1.href'),
        },
        {
          title: this.$t('tabs.3.title'),
          href: this.$t('tabs.3.href'),
        },
        {
          title: this.$t('tabs.2.title'),
          href: this.$t('tabs.2.href'),
          external: true,
        }
      ],
    }
  },
  watch: {
    $route() {
      this.updateRoute()
    },
  },
  mounted() {
    this.updateRoute()
  },
  methods: {
    updateRoute() {
      if (this.$route.name == this.localeRoute('discover-dfm-news-media').name) {
        this.$router.replace(
          this.localePath({
            name: 'discover-dfm-news-media-press-releases',
          })
        )
      }
    },
  },
}
