
// https://github.com/mengxiong10/vue2-datepicker
import DatePicker from 'vue2-datepicker'
import '~/assets/css/datepicker.css'
export default {
  name: 'Disclosures',
  components: {
    DatePicker,
  },
  fetchOnServer: false,
  data() {
    return {
      loading: true,
      disclosures: [],

      filter: {
        disclosureType: 'all',
        filterDateRange: [],
        filterKeyword: '',
        selectedCompanies: [],
      },
      filtered: {
        disclosureType: 'all',
        filterKeyword: '',
        filterDateRange: [],
        selectedCompanies: [],
      },
      disclosuresCount: 0,
      filterStatus: false,
      filterYear: '',

      itemsToGet: 20,
      currentPage: 1,
      isListOpen: false,

      disclosureTypes: [
        {
          title: 'All',
          value: 'all',
        },

        {
          title: 'General Meetings',
          value: 'general_meetings',
        },
        {
          title: 'Finance Reports',
          value: 'financial_reports',
        },
      ],
    }
  },

  computed: {
    disclosureTypeTitle() {
      if (this.filterStatus) {
        switch (this.filtered.disclosureType) {
          case 'all':
            return 'Disclosures'
            break
          case 'general_meetings':
            return 'General Meetings'
            break
          case 'financial_reports':
            return 'Finance Reports'
            break
          default:
            return 'Disclosures'
            break
        }
      }
      return this.filterStatus
    },
  },

  mounted() {
    this.fetchDisclosures(false)
  },

  methods: {
    async fetchDisclosures(status) {
      if (status) {
        this.currentPage = 1
      }
      let apiOptions = {
        params: {
          lang: this.$i18n.locale,
          h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
          take: this.itemsToGet,
          skip: (this.currentPage - 1) * this.itemsToGet,
          from: this.filter.filterDateRange[0],
          to: this.filter.filterDateRange[1],
          announcement_type: 'Disclosure',
          types: this.filter.disclosureType == 'all' ? '' : this.filter.disclosureType,
          symbol: this.filter.selectedCompanies.length ? this.filter.selectedCompanies.toString() : '',
          keyword: this.filter.filterKeyword,
          cms_resources: true,
        },
      }
      this.loading = true
      const disclosures = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah`, apiOptions)
      const disclosuresCount = await this.$axios.get(`${this.$config.apiEfsahUrl}/efsah_count`, apiOptions)
      this.disclosures = disclosures.data.root
      this.disclosuresCount = disclosuresCount.data.root[0].count
      this.filterStatus = status
      this.filtered.disclosureType = this.filter.disclosureType
      this.filtered.filterDateRange = this.filter.filterDateRange
      this.filtered.selectedCompanies = this.filter.selectedCompanies
      this.filtered.filterKeyword = this.filter.filterKeyword
      this.$refs.filterInput.filterKeyword = ''
      //Waste 5 seconds
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    getcompanies(companies) {
      this.filter.selectedCompanies = companies
    },
    setKeyword(keyword) {
      this.filter.filterKeyword = keyword
    },
    clearDateRange() {
      this.filter.filterDateRange = []
      this.filtered.filterDateRange = []
      this.filterStatus = false
      this.fetchDisclosures(false)
      this.currentPage = 1
    },
    clearSearch() {
      this.filter.filterDateRange = []
      this.filtered.filterDateRange = []
      this.filter.disclosureType = 'all'
      this.$refs.filterInput.filterKeyword = ''
      this.filterStatus = false
      this.filter.selectedCompanies = []
      this.fetchDisclosures(false)
      this.currentPage = 1
      this.$refs.companyselect.deleteSelectedCompanies()
    },
    updatePagination(page) {
      this.currentPage = page
      const myEl = this.$refs.disclosures
      this.$smoothScroll({
        scrollTo: myEl,
      })
      setTimeout(() => {
        this.fetchDisclosures(false)
      }, 500)
    },
    notAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },
  },
}
