
import listedCredits from '~/static/data/carboncredits.json'
export default {
  data() {
    return {
      listedCredits,
      security: {
        Name: 'Soubre Hydropower Project',
        Status: 'Retired',
        ID: 1522,
        Type: 'Energy industries (renewable/non-renewable sources)',
        Country: "Cote D'Ivoire",
        Vintage: 2021,
        'Total Vintage Quantity': '342,725',
        Verifier: 'Verra',
        Methodology: 'ACM0002',
        'Retirement / Cancellation Date': '05 July, 2021',
        'Retirement Beneficiary': 'MKS PAMP SA',
        'Retirement Reason': 'Retirement for Person or Organization',
        'Retirement Details': 'These offsets are being retired on behalf of MKS PAMP SA as part of their requirements for Carbon Neutrality certification given by the Carbon Trust',
        'Serial No': '12389-408761291-408772190-VCS-VCU-291-VER-CI-1-1522-01012021-30062021-0',
        'Quantity Retired': '10,900',
      },
    }
  },
  computed: {
    relatedCredits() {
      //return the conte with name matching something from listedcredits
      return this.listedCredits.filter((content) => content.Name.includes('Kariba'))
    },
  },
}
