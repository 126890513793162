
export default {
  name: 'MarketdataProviders',
  fetchOnServer: false,

  data() {
    return {
      providers: null,
      altLogos: null,
      selectedProvider: null,
      keyword: '',
      modalOpen: false,
    }
  },
  async fetch() {
    let providers = await fetch(`${this.$config.apiSfUrl}/vendors?$expand=LogoNew&$orderBy=Name&$select=Id,Name,RegisterDate,ContactPerson,Phone,ContactMobile,Fax,ContactEmail,Website,POBoxNumber,Location,LogoNew&sf_culture=${this.$i18n.locale}`).then((res) => res.json())

    let altLogos = await fetch(`${this.$config.apiSfUrl}/vendors?$expand=LogoNew&orderBy=Name&$select=Id,LogoNew&sf_culture=en`).then((res) => res.json())

    // this.providers.forEach(provider => {
    // });

    providers.value.map((provider) => {
      let altLogo = altLogos.value.find((altLogo) => altLogo.Id === provider.Id)
      if (altLogo) {
        provider.LogoAlt = altLogo.LogoNew
      }
    })
    this.providers = providers.value
    this.altLogos = altLogos.value
    this.selectedProvider = providers.value[0]
  },
  computed: {
    filteredProviders() {
      return this.providers.filter((provider) => {
        return provider.Name.toLowerCase().includes(this.keyword.toLowerCase())
      })
    },
    multipleWebsites() {
      var d = this.selectedProvider.Website.split(';')
      d.forEach((item, index) => {
        d[index] = item.trim()
      })
      return d
    },
  },
  methods: {
    setKeyword(val) {
      this.keyword = val
    },
    verifyLogo(logo) {
      if (!logo) {
        return 'https://assets.dfm.ae/images/member-logos/adcb.gif'
      } else {
        return `https://assets.dfm.ae/${logo.ItemDefaultUrl}.${logo.Extension}`
      }
    },

    openModal(provider) {
      this.selectedProvider = provider
      // this.modalOpen = true
      this.$refs.providerModal.showModal = true
    },
    closeModal() {
      this.modalOpen = false
    },

    convertDate(date) {
      var myDate = new Date(date)
      return myDate.toLocaleDateString()
    },
  },
}
