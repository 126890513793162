
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {
    features: {
      required: true,
      type: [Object, Array],
    },
    perView: {
      type: Number,
      default: 2.4,
    },
    breakpoints: {
      type: Object,
      default() {
        return {
          1024: {
            gap: 24,
            perView: 1.2,
          },
          600: {
            gap: 10,
            perView: 1,
          },
        }
      },
    },
  },
}
