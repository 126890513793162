import { render, staticRenderFns } from "./index.vue?vue&type=template&id=62c10e32&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeVideoBg: require('/vercel/path0/components/blocks/HomeVideoBg.vue').default,HomePageHero: require('/vercel/path0/components/HomePageHero.vue').default,TickerBlock: require('/vercel/path0/components/TickerBlock.vue').default,MostActiveTable: require('/vercel/path0/components/MostActiveTable.vue').default,MarketStatistics: require('/vercel/path0/components/MarketStatistics.vue').default,Tab: require('/vercel/path0/components/Tab.vue').default,Tabs: require('/vercel/path0/components/Tabs.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default})
