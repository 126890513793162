
import { parse } from 'node-html-parser'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    href: {
      type: String,
      default: '#',
    },
  },

  computed: {
    imgSrc() {
      if (this.data.Image && this.data.Image.length) {
        // return this.$config.assetsDomain + this.data.Image[0].ItemDefaultUrl
        return this.data.Image[0].Url.replace('http://www.dfm.ae', 'https://assets.dfm.ae').replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
      } else {
        const root = parse(this.data.LongDescription)
        const img = root.getElementsByTagName('img')
        if (img.length) {
          return img[0].attributes.src.replace('http://www.dfm.ae', 'https://assets.dfm.ae').replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
        }
      }
      return 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/c_thumb,w_400,g_face/v1674040329/visuals/visual-6_owdwy2'
    },
  },
  methods: {
    formattedDate(date) {
      return this.$dayjs(date).format('MMMM DD, YYYY')
    },
  },
}
