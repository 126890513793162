
export default {
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/image5_rso9vv.jpg',
    },
    alt: {
      type: String,
      default: 'Image',
    },
    title: {
      type: String,
      default: 'Image',
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: null,
    },
    crop: {
      type: String,
      default: 'lfill',
    },
    sizes: {
      type: String,
      default: 'sm:400px md:800px lg:1200px',
    },
    dpr: {
      type: String,
      default: 'auto',
    },
  },

  data() {
    return {
      modifiers: {
        // gravity: 'auto:subject',
        dpr: this.dpr,
      },
    }
  },
}
