
export default {
  name: 'HowToTrade',
  data() {
    return {
      faqs: [],
      timings: this.$t('timings'),
      brokers: this.$t('step1.brokers'),
      breakpoints: {
        800: {
          gap: 24,
          perView: 2,
        },
        600: {
          gap: 10,
          perView: 1,
        },
      },
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=faqs&section=investors&Language=${this.$i18n.locale}`,
      })

      var data = await response.json()
      this.faqs = data.Children
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    openModal(modal) {
      // this.selected = management
      // this.selectedType = type
      this.$refs[modal].showModal = true
    },
  },
}
