
export default {
  name: 'SecuritySymbolTrading',
  fetchOnServer: false,
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    dailySummary() {
      return this.$parent.dailySummary
    },
    isLinked() {
      if (!this.companyInfo) {
        return false
      }
      return this.companyInfo.IsLinked
    },
    notETF() {
      return this.companyInfo.InstrumentType != 'exchangeTradedFunds'
    },
    tabs() {
      return [
        {
          title: this.$t('data-headers.trading-summary'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/trading-summary/`,
        },
        {
          title: this.$t('data-headers.daily-summary'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/daily-summary/`,
        },
        {
          title: this.$t('data-headers.nav-summary'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/nav-summary/`,
          hide: this.notETF,
        },
        {
          title: this.$t('data-headers.top-shareholders'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/top-shareholders/`,
          hide: this.isLinked,
        },
        {
          title: this.$t('data-headers.foreign-investments'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/foreign-investments/`,
          hide: this.isLinked,
        },
      ]
    },
  },
  watch: {
    $route() {
      if (this.$route.name == this.localeRoute(`/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`).name) {
        this.$router.replace(
          this.localePath({
            path: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/trading-summary/`,
          })
        )
      }
    },
  },
  mounted() {
    if (this.$route.name == this.localeRoute(`/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`).name) {
      this.$router.replace(
        this.localePath({
          path: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/trading-summary/`,
        })
      )
    }
  },
}
