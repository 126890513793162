
export default {
  name: 'MarketIndices',
  fetchOnServer: false,

  data: () => ({
    indexCode: {
      dfmgi: '9999',
      dfmsi: '8888',
      uaeesgi: '7777',
    },
    loading: true,
    filterDateRange: [],
    selectedDateRange: [],
    uaesgiConstituents: [],
    dfmgi: null,
    stats: null,
    chart: null,
    yearlyClosing: null,
    dailyClosing: null,
    componentKey: 0,
    excelFields: {
      Date: 'd',
      'DFM Index': 'i',
      Change: 'c',
      'Chage %': 'cp',
    },
  }),
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchDFMIndex&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&IndexCode=${this.indexCode[this.selectedIndex]}&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()
      this.dfmgi = data
      this.stats = data.stats
      this.chart = data.chart
      this.yearlyClosing = data.yearly_closing
      this.dailyClosing = data.daily_closing
      this.selectedDateRange = this.filterDateRange
      this.loading = false

      // call top 5 constituents only for UAESGI
      if (this.selectedIndex === 'uaeesgi') {
        const top5 = await fetch(`${this.$config.apiBaseUrl}/data`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `Command=uaesgitop5constituents&Language=${this.$i18n.locale}&type=json`,
        })
        this.uaesgiConstituents = await top5.json()
      }
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    selectedIndex() {
      return this.$route.params.currentIndex
    },
    modifiedDailyClosing() {
      if (this.loading) return
      return this.dailyClosing.map((item) => {
        let TrendClass = item.c > 0 ? 'increase' : item.c < 0 ? 'decrease' : 'nocrease'
        return {
          ...item,
          ChangeTrendClass: TrendClass,
        }
      })
    },
    chartData() {
      if (this.loading) return
      return this.chart.points.map((point) => {
        return {
          date: this.$dayjs(point.Item1).format('YYYY-MM-DD'),
          currentIndex: point.Item2,
        }
      })
    },
  },
  watch: {
    $route() {
      this.$fetch()
      this.componentKey += 1
    },
  },
  mounted() {
    // console.log(this.$route.params.currentIndex)
    if (this.$fetchState.timestamp <= Date.now() - 30000) {
      this.$fetch()
    }
  },
  created() {
    // console.log(this.indexCode[this.$route.params.currentIndex])
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },

    clearSearch() {
      this.filterDateRange = []
    },
  },
}
