
export default {
  fetchOnServer: false,
  data() {
    return {
      dropdownOpen: false,
      status: '',
    }
  },

  computed: {
    statusData() {
      return this.$store.state.marketwatch.dfmgi
    },
    totalVolume() {
      return this.statusData.volume + this.statusData.blockvolume
    },
    totalValue() {
      return this.statusData.value + this.statusData.blockvalue
    },
    totalTrades() {
      return this.statusData.trades + this.statusData.totalblock
    },

    indexClass() {
      if (this.statusData.change > 0) {
        return 'up'
      } else if (this.statusData.change < 0) {
        return 'down'
      } else {
        return 'unchanged'
      }
    },
  },
  methods: {

    dropdownStatus(val) {
      // get the status of the dropdown so we can swap icons
      this.dropdownOpen = val
    },
  },
}
