
export default {
  name: 'DisclosureListSmall',
  fetchOnServer: false,
  props: {
    company: {
      type: String,
      default: 'dfm', // or nasdaq
    },
  },
  data: () => ({
    disclosures: [],
  }),
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshHomePageDisclosures&Market=${this.company}&Language=${this.$i18n.locale}`,
      })
      var data = await response.json()
      this.disclosures = data
    } catch (error) {
      // console.log(error)
    }
  },
}
