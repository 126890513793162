
import Autocomplete from '@trevoreyre/autocomplete-vue'
export default {
  name: 'SearchSymbol',
  fetchOnServer: false,
  components: {
    Autocomplete,
  },
  data() {
    return {
      stocks: [],
    }
  },
  async fetch() {
    try {
      const rawResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=LiteSecuritiesLists&Language=${this.$i18n.locale}`,
      })
      const data = await rawResponse.json()
      let combinedArray = [].concat(...Object.values(data))
      this.stocks = combinedArray
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    today() {
      return this.$dayjs().format('DD MMM, YYYY')
    },
  },
  mounted() {
    // dayjs set locale
    this.$dayjs.locale(this.$i18n.locale)
    // // console.log(this.$dayjs.locale())
  },
  methods: {
    search(input) {
      if (input.length < 1) {
        return []
      }
      return this.stocks
        .filter((stock) => {
          if (stock.FullName != null) {
            return stock.FullName.toLowerCase().includes(input.toLowerCase()) || stock.SecuritySymbol.toLowerCase().includes(input.toLowerCase())
          }
        })
        .slice(0, 5)
    },
    getResultValue(result) {
      return result.SecuritySymbol
    },
    handleSubmit(result) {
      var link = this.localePath(`/the-exchange/market-information/company/${result.SecuritySymbol}`)
      window.open(link, '_self')
    },
  },
}
