
export default {
  name: 'FamilyBusinesses',
  data() {
    return {
      benefits: this.$t('benefits'),
      markets: this.$t('markets'),
    }
  },
}
