
export default {
  name: 'TradeByNationality',
  fetchOnServer: false,

  data: () => ({
    filterDateRange: [],
    selectedDateRange: [],
    tradeNationality: [],
    excelFields: {
      Nationality: 'name',
      'Net Investment - AED': 'net_investment',
      'Value of stocks sold - AED': 'value_sold',
      'Value of stocks bought': 'value_bought',
    },
  }),
  async fetch() {
    try {
      this.loading = true
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchTradeNational&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()

      let totalForeignTrading = { name: 'Total Foreign Trading', net_investment: 0, value_sold: 0, value_bought: 0 }
      // add a new object inside data total of all
      data.sections.forEach((element) => {
        if (element.name != 'UAE Nationals') {
          totalForeignTrading.net_investment += element.net_investment
          totalForeignTrading.value_sold += element.value_sold
          totalForeignTrading.value_bought += element.value_bought
        }
      })
      data.sections.splice(3, 0, totalForeignTrading)
      data.sections.push({
        name: 'Total',
        net_investment: data.total_net_investment,
        value_sold: data.total_value_sold,
        value_bought: data.total_value_bought,
      })
      this.tradeNationality = data.sections
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },

  computed: {
    localeLabel() {
      return (name) => {
        if (name.toLowerCase() == 'arab') {
          return this.$t('data-headers.th5')
        } else if (name.toLowerCase() == 'gcc') {
          return this.$t('data-headers.th6')
        } else if (name.toLowerCase() == 'others') {
          return this.$t('data-headers.th7')
        } else if (name.toLowerCase() == 'uae nationals') {
          return this.$t('data-headers.th8')
        } else if (name.toLowerCase() == 'total foreign trading') {
          return this.$t('data-headers.th9')
        } else if (name.toLowerCase() == 'total') {
          return this.$t('data-headers.th10')
        } else {
          return ''
        }
      }
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
