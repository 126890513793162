
export default {
  name: 'MobileApp',
  data() {
    return {
      appVideo: this.$t('appVideo'),
      enhancements: Object.values(this.$t('enhancements.blocks')),
    }
  },
  computed: {
    features() {
      return Object.values(this.$t('features.blocks'))
    },
    availableLocales() {
      return this.$i18n.locales
    },
    currentLocale() {
      return this.$i18n.locales.filter((i) => i.code == this.$i18n.locale)
    },
    showModal() {
      if (this.$route.matched.length > 1) {
        return true
      }
      return false
    },
  },

}
