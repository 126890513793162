
import _sortBy from 'lodash/sortBy'
fetchOnServer: false
export default {
  name: 'CompanyProfile',
  fetchOnServer: false,
  scrollToTop: true,
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allSectors: null,
      companyProfileInfo: null,
    }
  },
  async fetch() {
    try {
      const companyProfileInfoRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=companyprofile&lang=${this.$i18n.locale}&symbol=${this.$route.params.SecuritySymbol}`,
      })

      this.companyProfileInfo = await companyProfileInfoRes.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    isPreListing() {
      if (!this.companyInfo) {
        return false
      }
      return this.companyInfo.ISIN === '-'
    },
    isLinked() {
      if (!this.companyInfo) {
        return false
      }
      return this.companyInfo.IsLinked
    },
    linkedSymbol() {
      if (this.companyInfo.IsLinked) {
        return this.companyInfo.LinkedSymbol
      }
      return this.$route.params.SecuritySymbol
    },
    boardMembers() {
      if (!this.companyProfileInfo || !this.companyProfileInfo.Board || this.companyProfileInfo.Board.length === 0) {
        return []
      }
      var members = this.companyProfileInfo.Board.filter((member) => member.Name != '')
      return _sortBy(members, ['Order'])
    },
    topManagement() {
      if (!this.companyProfileInfo || !this.companyProfileInfo.TopManagement || this.companyProfileInfo.TopManagement.length === 0) {
        return []
      }
      var mgmt = this.companyProfileInfo.TopManagement.filter((mgmt) => mgmt.Name != '')
      return _sortBy(mgmt, ['Order'])

    },
    subsidiaries() {
      if (!this.companyProfileInfo) {
        return []
      }
      return this.companyProfileInfo.Subsidiaries
    },
    contacts() {
      if (!this.companyProfileInfo) {
        return []
      }
      return this.companyProfileInfo.ContactDetails
    },
    documents() {
      let allDocuments = []
      if (!this.companyProfileInfo) {
        return []
      }
      if (this.companyProfileInfo.Prospectus) {
        allDocuments.push(this.companyProfileInfo.Prospectus)
      }
      if (this.companyProfileInfo.ArticlesofAssociation && this.companyProfileInfo.ArticlesofAssociation.length) {
        allDocuments.push(...this.companyProfileInfo.ArticlesofAssociation)
      }
      return allDocuments
    },
  },
  // async fetch() {
  //   let sectors = await this.$axios.get(`${this.$config.apiSfUrl}/sectors`)
  //   this.allSectors = sectors.data.value
  // },
  methods: {
    convertDomain(url) {
      if (url) {
        url = url.replace('https://www.dfm.ae', this.$config.assetsDomain)
        return url
      }
      return ''
    },
    cleanDomain(url) {
      if (typeof url !== 'string') {
        return url
      } else if (url.startsWith('http://')) {
        return url.replace('http://', 'https://')
      } else if (url.startsWith('www.')) {
        return url.replace('www.', 'https://www.')
      }

      return url
    },
  },
}
