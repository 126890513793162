
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    rating: {
      type: Number,
      default: 0,
    },
  },
}
