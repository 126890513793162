import { render, staticRenderFns } from "./index.vue?vue&type=template&id=470c6186&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,VueSimpleAccordion: require('/vercel/path0/components/VueSimpleAccordion.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default})
