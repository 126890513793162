import { render, staticRenderFns } from "./MemberLogoCard.vue?vue&type=template&id=806f5756&"
import script from "./MemberLogoCard.vue?vue&type=script&lang=js&"
export * from "./MemberLogoCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CImage: require('/vercel/path0/components/ui/c-image.vue').default})
