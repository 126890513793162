
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: { VueSlickCarousel },
  props: {
    features: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    gotoSlide(event) {
      let el = event.currentTarget
      let s = event.currentTarget.getAttribute('data-slide')
      let cards = document.getElementsByClassName('feature-card')
      cards.forEach((element) => {
        element.classList.remove('active')
      })
      el.classList.add('active')
      this.$refs.carousel.goTo(s)
    },
  },
}
