
export default {
  data() {
    return {
      tabs: [
        {
          title: 'DFMGI',
          href: '/',
        },
        {
          title: 'DFMSI',
          href: '/the-exchange/market-information/indices/dfmsi',
          type: 'external',
        },
        {
          title: 'UAEESGI',
          href: '/the-exchange/market-information/indices/uaeesgi',
          type: 'external',
        },
      ],
      selectedIndex: 0, // the index of the selected tab,
    }
  },
}
