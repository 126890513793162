
import orderBy from 'lodash/orderBy'

export default {
  name: 'CorporateActions',
  components: {
    VCalendar: () => import('~/node_modules/v-calendar/lib/components/calendar.umd'),
  },
  fetchOnServer: false,
  data() {
    return {
      mounted: false,
      loading: true,
      corporateActions: [],
      filterKeyword: '',
      type: 'all',
      month: '',
      year: '',

      sortField: 'dateObj',
      sortOrder: 'asc',
      masks: {
        weekdays: 'WWW',
      },
      view: 'list',
    }
  },
  computed: {
    currentYear() {
      return this.$dayjs().format('YYYY')
    },
    currentMonth() {
      return this.$dayjs().format('MMMM')
    },
    currentDay() {
      return this.$dayjs().format('DD')
    },
    filteredCorporateActions() {
      if (this.type === 'all') {
        return this.corporateActions.filter((action) => action.type != this.type && action.msg.toLowerCase().includes(this.filterKeyword.toLowerCase()))
      } else {
        return this.corporateActions.filter((action) => action.type === this.type && action.msg.toLowerCase().includes(this.filterKeyword.toLowerCase()))
      }
    },
    attributes() {
      return [
        ...this.corporateActions.map((action) => ({
          key: action.linkid,
          dates: new Date(this.$dayjs(action.date, 'DD-MM-YYYY')),
          popover: {
            label: action.msg,
          },
          customData: action,
        })),
      ]
    },
  },
  mounted() {
    this.mounted = true
    this.month = this.$dayjs().format('M')
    this.year = this.$dayjs().format('YYYY')
    // this.fetchCorporateActions() //Not calling in mounted since we are calling when calendar emit an update event
  },

  methods: {
    whenCalendarUpdated(e) {
      this.loading = true
      this.month = e.month
      this.year = e.year
      this.fetchCorporateActions()
      this.loading = false
    },

    async fetchCorporateActions() {
      this.loading = true
      const rawResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetAssemblyGeneralAssembly&Language=${this.$i18n.locale}&Year=${this.year}&Month=${this.month}`,
      })
      this.corporateActions = await rawResponse.json()
      this.corporateActions = orderBy(this.corporateActions, ['dateObj'], ['asc'])
      this.loading = false

      const calendar = this.$refs.calendar
      await calendar.move({
        month: parseInt(this.month),
        year: parseInt(this.year),
      })
    },

    convertMonth(month) {
      if (month == '0') return ''
      return this.$dayjs()
        .month(month - 1)
        .format('MMMM')
    },

    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
    sortedCorporateActions(field) {
      this.sortField = field
      this.sortOrder == 'asc' ? (this.sortOrder = 'desc') : (this.sortOrder = 'asc')
      this.corporateActions = orderBy(this.corporateActions, field, this.sortOrder)
    },
    filterByType() {
      if (this.type === 'all') {
        this.corporateActions = this.corporateActions.filter((action) => action.type != this.type)
      } else {
        this.corporateActions = this.corporateActions.filter((action) => action.type === this.type)
      }
    },
    actionType(type) {
      switch (type) {
        case 'meetingdate':
          return `${this.$t('commons.ga')}`
          break
        case 'bodmeeting':
          return `${this.$t('commons.bod')}`
          break
        case 'rgam':
          return `${this.$t('commons.rgam')}`
          break
        case 'earningcallmeeting':
          return `${this.$t('commons.earningcallmeeting')}`
          break
        case 'holiday':
          return `${this.$t('commons.public-holiday')}`
          break
        case 'led':
          return `${this.$t('commons.led')}`
          break
        case 'exd':
          return `${this.$t('commons.exd')}`
          break
        case 'rcd':
          return `${this.$t('commons.rcd')}`
          break
        default:
          return '--'
      }
    },
  },
}
