
export default {
  fetchOnServer: false,
  data() {
    return {
      mkdInfo: null,
      initialLoad: true,
    }
  },




}
