import { render, staticRenderFns } from "./sharia-compliance.vue?vue&type=template&id=4752f39f&"
import script from "./sharia-compliance.vue?vue&type=script&lang=js&"
export * from "./sharia-compliance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/sharia-compliance-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fsharia-compliance.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/sharia-compliance-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fthe-exchange%2Fregulation%2Fsharia-compliance.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default,FaqsAccordionMulti: require('/vercel/path0/components/datablocks/FaqsAccordionMulti.vue').default})
