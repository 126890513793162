
export default {
  name: 'ContactUs',
  data() {
    return {
      submittingForm: false,
      showSuccess: false,
      formEndPoint: 'https://assets.dfm.ae/forms---ajax/contact-us/AjaxSubmit',
      form: {
        FormId: 'b2d284f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: 'f6d484f8-f602-6339-b0d9-ff00009be840',
        Department: this.$t('departments[0].value'),
        FirstName: '',
        LastName: '',
        EmailTextFieldController: '',
        Phone: '',
        Message: '',
        action: 'validate_captcha',
      },
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        // console.log('ReCaptcha token:', token)
        const formData = new FormData()
        formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('Department', this.form.Department), formData.append('FirstName', this.form.FirstName), formData.append('LastName', this.form.LastName), formData.append('EmailTextFieldController', this.form.EmailTextFieldController), formData.append('Phone', this.form.Phone), formData.append('Message', this.form.Message), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/formapi', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
          }, 3000)
        }
      } catch (error) {
        // console.log('Login error:', error)
      }
    },
    resetForm() {
      this.form.FirstName = ''
      this.form.LastName = ''
      this.form.EmailTextFieldController = ''
      this.form.Phone = ''
      this.form.Message = ''
    },
  },
}
