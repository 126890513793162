
export default {
  name: 'UpdateInfo',
  layout: 'blank',
  mounted() {
    // // console.log(this.$device)
    if (this.$device.isAndroid) {
      // alert('android')
      setTimeout(() => {
        window.location.href = this.$googleStoreLink
      }, 2000)
    } else if (this.$device.isIos) {
      // alert('ios')
      setTimeout(() => {
        window.location.href = this.$appleStoreLink
      }, 2000)
    } else if (this.$device.isDesktop) {
      // alert('desktop')
      this.$router.push(this.localePath('/investing/services/mobile-app/updateinfo'))
    }
  },
}
