
export default {
  name: 'PressReleaseDetail',
  scrollToTop: true,
  fetchOnServer: false,
  data() {
    return {
      pressRelease: null,
    }
  },

  watch: {
    $route() {
      // console.log('watched')
      this.fetchPressRelease()
    },
  },
  mounted() {
    this.fetchPressRelease()
  },
  methods: {
    async fetchPressRelease() {
      try {
        const response = await fetch(`${this.$config.apiEfsahUrl}/detail?id=${this.$route.params.id}`, {
          method: 'GET',
        })
        var data = await response.json()
        this.pressRelease = data.root[0]
      } catch (error) {
        console.log(error)
      }

    },
  },

}
