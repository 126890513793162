
export default {
  name: 'EventDetail',
  scrollToTop: true,
  fetchOnServer: false,
  data() {
    return {
      event: null,
    }
  },
  async fetch() {
    const res = await this.$axios.get(`${this.$config.apiSfUrl}/dfmevents`, {
      params: {
        $filter: `Id eq ${this.$route.params.id}`,
        $expand: 'Image',
        sf_culture: this.$i18n.locale,
      },
    })

    this.event = res.data.value[0]
  },

  deactivated() {
    this.event = null
  },
  activated() {
    this.$fetch()
  },
  fetchOnServer: false,
}
