


export default {
  name: 'DfmBrokersMembers',
  fetchOnServer: false,

  data() {
    return {
    }
  },

  methods: {
    openUrl(provider) {
      // open the url in a new tab
      window.open(provider.website, '_blank');
    },

    //arabic language api dont have arabic logos, so we switch to english logos if the array dont have values
    verifyLogo(broker) {
      if (!broker) return
      if (broker.BrokerLogoNew.length) {
        return this.$config.assetsDomain + broker.BrokerLogoNew[0].ItemDefaultUrl + broker.BrokerLogoNew[0].Extension
      } else {
        if (broker.LogoAlt) {
          return this.$config.assetsDomain + broker.LogoAlt[0].ItemDefaultUrl + broker.LogoAlt[0].Extension
        }
      }
    },
    convertDate(date) {
      const d = new Date(date)
      return d.toLocaleDateString()
    },
    currentStatus(status) {
      if (status === '1') {
        return this.$t('commons.active')
      }
      if (status === '2') {
        return this.$t('commons.inactive')
      }
      return '--'
    },
  },
}
