
export default {
  name: 'MarketIndices',
  data() {
    return {
      currentIndex: 'dfmgi',
    }
  },

  mounted() {
    // // console.log(this.$route)
    // // console.log(this.localeRoute('the-exchange-market-information-indices').path)
    this.$router.replace(
      this.localePath({
        path: `/the-exchange/market-information/indices/${this.currentIndex}`,
      })
    )
  },
}
