import { render, staticRenderFns } from "./UnclaimedDividends.vue?vue&type=template&id=1ab47926&"
import script from "./UnclaimedDividends.vue?vue&type=script&lang=js&"
export * from "./UnclaimedDividends.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UnclaimedDividends.vue?vue&type=custom&index=0&blockType=i18n&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./UnclaimedDividends.vue?vue&type=custom&index=1&blockType=i18n&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
