
export default {
  name: 'UnionCoop',
  fetchOnServer: false,

  data() {
    return {
      features: this.$t('features'),
      sliderbreakpoints: {
        1024: {
          gap: 24,
          perView: 1.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
}
