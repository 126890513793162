
export default {
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/dfm-image-00749_rfxe9n',
    },
    width: {
      type: Number,
      default: 1200,
    },
    height: {
      type: Number,
      default: 600,
    },
    crop: {
      type: String,
      default: 'lfill',
    },
  },
}
