
export default {
  fetchOnServer: false,

  components: {
    MarqueeText: () => (process.client ? import('vue-marquee-text-component') : ''),
  },
  props: {
    tickerData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { paused: false }
  },
}
