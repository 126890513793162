
export default {
  name: 'FooterContent',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    currentLocale() {
      return this.$i18n.locales.filter((i) => i.code == this.$i18n.locale)
    },
  },
}
