
export default {
  name: 'PjscZakat',
  fetchOnServer: false,
  data() {
    return {
      selectedZakat: {},
      shares: 0,
      zakats: [],
      charityList: this.$t('s2.charityList'),
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'Command=GetZakatCalculatorDetails&Symbol=DFM',
      })
      let json = await response.json()
      this.zakats = json[0].Rates
      this.selectedZakat = this.zakats[this.zakats.length - 1]
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    zakatAmount() {
      return (this.shares * this.selectedZakat.Rate) / 1000
    },
    chartData() {
      let data = this.zakats
      let tmp = []
      data.forEach((el) => {
        tmp.push([
          el.Year, // the date
          el.Rate, // close
        ])
      })
      return tmp
    },
  },
}
