
import Meta from '~/mixins/meta'

export default {
  name: 'DirectDeals',
  fetchOnServer: false,

  mixins: [Meta],

  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      directDeals: [],
      keyword: '',
      filterDateRange: [],
      selectedDateRange: [],
      filterKeyword: '',
      networkError: false,
      errorMsg: '',
      excelFields: {
        Date: 'date',
        Symbol: 'symbol',
        'Company Name': 'name',
        Volume: 'volume',
        Value: 'value',
        'No. of Trades': 'trades',
        'Execution Price': 'price',
      },
    }
  },
  async fetch() {
    try {
      this.networkError = false
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=directdeals&symbol=all&from=${this.filterDateRange[0]}&to=${this.filterDateRange[1]}&Language=${this.$i18n.locale}`,
      })
      this.directDeals = await response.json()
      this.selectedDateRange = this.filterDateRange
    } catch (error) {
      this.networkError = true
      this.errorMsg = this.$t('commons.network-error')
    }
  },
  computed: {
    filteredDeals() {
      if (this.keyword) {
        return this.directDeals.filter((item) => {
          return item.name.toLowerCase().includes(this.keyword.toLowerCase()) || item.symbol.toLowerCase().includes(this.keyword.toLowerCase())
        })
      } else {
        return this.directDeals
      }
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(2, 'year').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  },
  methods: {
    setKeyword(val) {
      this.keyword = val
    },
    dateChanged(val) {
      this.filterDateRange = val
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
