
export default {
  name: 'VideoCard',
  props: {
    video: {
      required: true,
    },
  },
  methods: {
    playVideo: function (videoId) {
      this.$emit('playVideo', videoId)
    },
  },
}
