
export default {
  props: {
    alignRight: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: 'Select..',
    },
    selected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isListOpen: false,
    }
  },

  methods: {
    closeListBox() {
      this.isListOpen = false
    },
  },
}
