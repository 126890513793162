

export default {
  name: 'DividendDistribution',
  fetchOnServer: false,
  data() {
    return {
      loading: true,
      dividends: [],
      dividendYear: "2024",
      years: [],
      excelFields: {
        Symbol: 'Symbol',
        'AGM Date': 'AGM',
        'Entitlement Date': 'EDate',
        'xDividend Date': 'ExDate',
        'Settlement Date': 'SDate',
        Dividend: 'DividendPercentage',
        'Payment Date': 'BankTransferSMSDate',
      },
    }
  },

  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=getdividendsconfig&year=${this.dividendYear}`,
      })
      this.dividends = await response.json()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    sortedDividends() {
      return this.dividends.slice().sort((a, b) => (a.Symbol > b.Symbol ? 1 : -1))
    }
  },

  mounted() {
    let arr = []
    for (let i = this.$dayjs().format('YYYY'); i >= 2017; i--) {
      arr.push(i)
    }
    this.years = arr
  },
  methods: {
    fetchDividends() {
      this.$fetch()
    },

    // get nuxt axios post method

    disclosureURL(dividend) {
      if (this.$i18n.locale == 'en') {
        return dividend.DisclosureURLEnglish
      } else {
        return dividend.DisclosureURLArabic
      }
    },
  }
}
