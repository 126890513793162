
export default {
  props: {
    type: {
      type: String,
      default: 'pdf', //video, pdf or word
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Document",
    },
    subtitle: {
      type: String,
      default: '',
    },
    cover: {
      type: String,
      default: 'icons/icon-cover-placeholder_veaced',
    },
    href: {
      type: String,
      default: '/',
    },
  },
}
