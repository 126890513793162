

export default {
  name: 'EboardNotificationForm',
  props: {
    company: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        FormId: '7c5096f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: 'd95296f8-f602-6339-b0d9-ff00009be840',
        Name: '',
        JobTitle: '',
        Gender: '',
        Email: '',
        ConfirmEmail: '',
        AgreeTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      submittingForm: false,
    }
  },
  computed: {
    CountryOfResidence() {
      return this.$refs['country-dropdown'].selectedCountry
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm() {
      try {
        if (this.form.Email !== this.form.ConfirmEmail) {
          this.$notify({
            title: this.$t('msgs.1.title'),
            text: this.$t('msgs.1.text'),
            type: 'error'
          })
          return;
        }
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        // Check email confirm validation
        const formData = new FormData()
        formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('JobTitle', this.form.JobTitle), formData.append('Name', this.form.Name), formData.append('ConfirmEmail', this.form.ConfirmEmail), formData.append('Email', this.form.Email), formData.append('AgreeTerms', this.form.AgreeTerms), formData.append('Gender', this.form.Gender), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/eboardNotificationForm', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.$notify({
            title: this.$t('msgs.0.title'),
            text: this.$t('msgs.0.text'),
            type: 'success'
          })
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 3000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.Name = ''
      this.form.JobTitle = ''
      this.form.Gender = ''
      this.form.Email = ''
      this.form.ConfirmEmail = ''
      this.form.AgreeTerms = false
      this.$recaptcha.destroy()
    },
  },
}
