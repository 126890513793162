import { render, staticRenderFns } from "./GeneralNews.vue?vue&type=template&id=05034d84&"
import script from "./GeneralNews.vue?vue&type=script&lang=js&"
export * from "./GeneralNews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,GeneralNewsCard: require('/vercel/path0/components/GeneralNewsCard.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default})
