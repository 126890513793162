
export default {
  name: 'CorporateActions',
  fetchOnServer: false,
  data() {
    return {
      corporateActions: null,
    }
  },
  async fetch() {
    try {
      const actionsRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshCorporateActions&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}`,
      })
      this.corporateActions = await actionsRes.json()
    } catch (error) {
      // console.log(error)
    }
  },
}
