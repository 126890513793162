
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  name: 'DFMEvents',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  fetchOnServer: false,
  data() {
    return {
      loading: true,
      latestEvents: [],
      events: [],
      eventsCount: 0,
      filterStatus: false,
      filterYear: '',
      filterKeyword: '',
      lastFilterKeyword: '',
      lastFilterYear: '',

      itemsToGet: 100,
      currentPage: 1,
      // apiOptions:'',
      breakpoints: {
        800: {
          gap: 24,
          perView: 2.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
  async fetch() {
    try {
      const latestEvents = await this.$axios.get(`${this.$config.apiSfUrl}/dfmevents`, {
        params: {
          $top: 4,
          $orderby: 'DateTime desc',
          sf_culture: this.$i18n.locale,
        },
      })
      this.latestEvents = latestEvents.data.value
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    currentYear() {
      return this.$dayjs().format('YYYY')
    },
    currentMonth() {
      return this.$dayjs().format('MMMM')
    },
    currentDay() {
      return this.$dayjs().format('DD')
    },
    endDate() {
      if (this.filterYear === 'all') {
        return ''
      }
      return `${this.filterYear}-12-31`
    },
    startDate() {
      if (this.filterYear === 'all') {
        return ''
      }
      return `${this.filterYear}-01-01`
    },
    itemsToSkip() {
      return (this.currentPage - 1) * this.itemsToGet
    },
    apiOptions() {
      if (this.filterYear === 'all') {
        return {
          params: {
            $orderby: 'DateTime desc',
            $skip: this.itemsToSkip,
            $top: this.itemsToGet,
            $count: true,
            $filter: `contains(Title,'${this.filterKeyword}')`,
            $expand: 'Image',
            sf_culture: this.$i18n.locale,
          },
        }
      }
      return {
        params: {
          $orderby: 'DateTime desc',
          $skip: this.itemsToSkip,
          $top: this.itemsToGet,
          $count: true,
          $filter: `DateTime gt ${this.filterYear}-01-01 and DateTime lt ${this.filterYear}-12-31 and contains(Title,'${this.filterKeyword}')`,
          $expand: 'Image',
          sf_culture: this.$i18n.locale,
        },
      }
    },
  },
  mounted() {
    // this.filterYear = this.$dayjs().format('YYYY')
    this.filterYear = this.$dayjs().format('YYYY')
    this.fetchEvents(false)
  },
  beforeDestroy() {
    if (this.glide) this.glide.destroy()
  },

  methods: {
    async fetchEvents(status) {
      this.loading = true
      if (status) {
        this.currentPage = 1
      }
      const eventList = await this.$axios.get(`${this.$config.apiSfUrl}/dfmevents`, this.apiOptions)
      // var response = await eventList.json()
      this.events = eventList.data.value
      this.eventsCount = eventList.data['@odata.count']
      this.filterStatus = status
      this.lastFilterKeyword = this.filterKeyword
      this.lastFilterYear = this.filterYear
      //Waste 5 seconds
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    clearSearch() {
      this.$refs.filterInput.clearInput()
      // this.filterKeyword = ''
      this.filterStatus = false
      this.fetchEvents(false)
      this.currentPage = 1
    },
    updatePagination() {
      this.currentPage = page
      const myEl = this.$refs.events
      this.$smoothScroll({
        scrollTo: myEl,
      })
      setTimeout(() => {
        this.fetchEvents(false)
      }, 500)
    },
    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
  },
}
