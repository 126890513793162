
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    email: {
      type: String,
      default: "business@dfm.ae"
    },

    btnText: {
      type: String,
      default: 'Button Text',
    },
    linkTo: {
      type: String,
      default: '/',
    },
  },
}
