
export default {
  data() {
    return {
      filterAnnouncement: [
        {
          value: "DFM News, Derivative News",
          label: this.$t('market-announcement-label')
        },
        {
          value: "Derivative News",
          label: this.$t('derivatives-announcement-label')
        }
      ],
      selectedAnnouncementType: {
        value: "DFM News, Derivative News",
        label: this.$t('market-announcement-label')
      },
    }
  },
  computed: {
    reactiveFilterAnnouncement() {
      return this.selectedAnnouncementType
    },
  },
  watch: {
    selectedAnnouncementType: function () {
      this.$emit('get-announcements', this.reactiveFilterAnnouncement)
      this.$refs.dropdown.closeListBox()
    },
  },
  mounted() {
    console.log(this.$route.query.type)
    if (this.$route.query.type == "derivatives") {
      this.selectedAnnouncementType = {
        value: "Derivative News",
        label: this.$t('derivatives-announcement-label')
      }
    }
    this.$emit('get-announcements', this.reactiveFilterAnnouncement)
  },
}
