
export default {
  name: 'DecoratorLine',
  props: {
    color: {
      type: String,
      default: 'green',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
