
export default {
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/dfm-visual-6_b308gh.jpg',
    },
    gravity: {
      type: String,
      default: 'auto:subject',
    },
  },

  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasContentSlot() {
      return !!this.$slots.content
    },
  },
}
