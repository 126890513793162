
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
  name: 'PressReleases',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  fetchOnServer: false,
  data() {
    return {
      loading: false,
      disclosures: [],
      disclosuresCount: 0,
      filterStatus: false,
      filterYear: '',
      lastFilterYear: '',
      filterKeyword: '',
      lastFilterKeyword: '',
      itemsToGet: 20,
      currentPage: 1,

      breakpoints: {
        800: {
          gap: 24,
          perView: 2.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },

  computed: {
    latestDisclosures() {
      return this.$store.state.latestContent.latestPress
    },
    currentYear() {
      return this.$dayjs().format('YYYY')
    },
    currentMonth() {
      return this.$dayjs().format('MMMM')
    },
    currentDay() {
      return this.$dayjs().format('DD')
    },
    endDate() {
      if (this.filterYear === 'all') {
        return ''
      }
      return `${this.filterYear}-12-31`
    },
    startDate() {
      if (this.filterYear === 'all') {
        return ''
      }
      return `${this.filterYear}-01-01`
    },
  },
  mounted() {
    this.filterYear = this.$dayjs().format('YYYY')
    this.fetchDisclosures(false)
  },
  beforeDestroy() {
    if (this.glide) this.glide.destroy()
  },

  methods: {
    async fetchDisclosures(status) {
      if (status) {
        this.currentPage = 1
      }
      let apiOptions = {
        params: {
          lang: this.$i18n.locale,
          h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
          take: this.itemsToGet,
          skip: (this.currentPage - 1) * this.itemsToGet,
          from: this.startDate,
          to: this.endDate,
          labels: 'pr',
          symbol: 'DFM',
          keyword: this.filterKeyword,
          cms_resources: true,
        },
      }
      this.loading = true
      const disclosures = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah`, apiOptions)
      const disclosuresCount = await this.$axios.get(`${this.$config.apiEfsahUrl}/efsah_count`, apiOptions)
      this.disclosures = disclosures.data.root
      this.disclosuresCount = disclosuresCount.data.root[0].count
      this.filterStatus = status
      this.lastFilterYear = this.filterYear
      this.lastFilterKeyword = this.filterKeyword
      this.$refs.filterInput.clearInput()
      // this.filterKeyword = ''
      //Waste 5 seconds
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    clearSearch() {
      this.$refs.filterInput.clearInput()
      this.filterStatus = false
      this.fetchDisclosures(false)
      this.currentPage = 1
    },
    updatePagination(page) {
      this.currentPage = page
      const myEl = this.$refs.disclosures
      this.$smoothScroll({
        scrollTo: myEl,
      })
      setTimeout(() => {
        this.fetchDisclosures(false)
      }, 500)
    },
    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
  },
}
