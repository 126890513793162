
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'OilFutures',
  components: { vueVimeoPlayer },
  data() {
    return {
      priceDirection: 'up',
      currentVideoId: '712821643',
    }
  },
  methods: {
    playVideo: function (videoId) {
      this.currentVideoId = videoId
      this.$refs.dmevideo.showModal = true
      setTimeout(() => {
        this.$refs.player.play()
      }, 2000)
    },

    modalClosed() {
      // console.log('modal closed')
      this.$refs.player.pause()
    },
  },
}
