
import startCase from 'lodash/startCase'
export default {
  name: 'EboardNominations',
  async asyncData({ req, context, i18n }) {
    let currentLocale = i18n.locale
    if (currentLocale == 'en') {
      const nominations = await import('~/static/data/eboard-nominations-en.json').then((m) => m.default || m)
      return { nominations: nominations }
    }
    else {
      const nominations = await import('~/static/data/eboard-nominations-ar.json').then((m) => m.default || m)
      return { nominations: nominations }
    }

  },
  data() {
    return {
      headers: this.$t('nominations.headers'),
      selectedPosition: null,
    }
  },

  methods: {
    openModal(modal, position) {
      // console.log(this.$refs[modal])
      this.selectedPosition = position
      this.activeModal = modal
      this.$refs[modal].showModal = true
    },
    startCase(str) {
      return startCase(str)
    },
  },
}
