
export default {
  name: 'MarketAnnouncements',
  fetchOnServer: false,
  data: () => ({
    marketAnnouncements: [],
  }),
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshHomePageAnnouncements&Type=market_announcements&Language=${this.$i18n.locale}`,
      })
      var data = await response.json()
      this.marketAnnouncements = data
    } catch (error) {
      // console.log(error)
    }
  },
}
