
export default {
  props: {
    security: {
      type: Object,
      default: {},
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    convertDomain(url) {
      if (!url) {
        this.security.SecurityType == 'Sukuk' ? (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/sukuk-logo_n6ovab') : this.security.SecurityType == 'Bond' ? (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/bond-logo_py7tel') : (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/fund-logo_fpxpd1')
        return url
      }
      url = url.replace('https://www.dfm.ae', this.$config.assetsDomain)
      return url
    },
  },
}
