
export default {
  name: 'IvestorLayout',
  head() {
    return this.$nuxtI18nHead({ addDirAttribute: true, addSeoAttributes: true })
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },

}
