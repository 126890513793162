
export default {
  name: 'Reits',
  fetchOnServer: false,

  data() {
    return {
      featuresPersonal: this.$t('featuresPersonal'),
      featuresInvestors: this.$t('featuresInvestors'),
      brokers: [
        {
          name: 'Al Ramz Capital L.L.C',
          website: 'alramz.ae',
          logo: 'al-ramz-logo@2x.png',
          phone: '+971 02 626 2626 ',
          email: 'info@alramz.ae',
        },
        {
          name: 'Al Dar Shares and Bonds',
          website: 'aldarshares.ae',
          logo: 'aldar-logo@2x.png',
          phone: '+971 02 612 6666 ',
          email: 'ayman@aldarshares.com',
        },
        {
          name: 'Arqaam Securities ',
          website: 'arqaamcapital.com',
          logo: 'arqaam-logo@2x.png',
          phone: '+971 04 507 1756 ',
          email: 'operations@arqaamsecurities.com',
        },
        {
          name: 'BHM Capital Financial Services P.S.C',
          website: 'www.bhmuae.ae',
          logo: 'bhm-capital-logo@2x.png',
          phone: '800 567 000',
          email: 'callcenter@bhmuae.ae',
        },
        {
          name: 'EFG Hermes (UAE)  ',
          website: 'hermesonline.com',
          logo: 'efg-hermes-logo@2x.png',
          phone: '600 500 004',
          email: 'uae-ops@efg-hermes.com',
        },
        {
          name: 'SICO Financial Brokerage L.L.C.',
          website: 'sicouae.com',
          logo: 'sico-logo@2x.png',
          phone: '+971 02 659 0100',
          email: 'info@sicouae.com',
        },
        {
          name: 'International Securities L.L.C',
          website: 'intlsecurities.ae',
          logo: 'international-securities-logo@2x.png',
          phone: '+971 02 494 2777',
          email: 'compliance@intlsecurities.ae ',
        },
        {
          name: 'Mena Corp Financial Services L.L.C',
          website: 'menacorpfinance.com',
          logo: 'menacorp-logo@2x.png',
          phone: '+971 02 493 9000',
          email: 'info@menacorpfinance.com',
        },
      ],

      sliderbreakpoints: {
        1024: {
          gap: 24,
          perView: 1.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
}
