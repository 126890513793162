
import startCase from 'lodash/startCase'

export default {
  name: 'EboardPositions',
  async asyncData({ req, context, i18n }) {
    let currentLocale = i18n.locale
    if (currentLocale == 'en') {
      const positions = await import('~/static/data/eboard-positions-en.json').then((m) => m.default || m)
      return positions
    }
    else {
      const positions = await import('~/static/data/eboard-positions-ar.json').then((m) => m.default || m)
      return positions
    }

  },
  data() {
    return {
      headers: this.$t('positions.headers'),
      selectedPosition: null,
    }
  },
  computed: {
    currentPositions() {
      return this.positions.filter((position) => position.status == 'open' || position.status == 'متاح')
    },
    previousPositions() {
      return this.positions.filter((position) => position.status == 'closed' || position.status == 'مغلق')
    },
  },
  methods: {
    openModal(modal, position) {
      this.selectedPosition = position
      this.activeModal = modal
      this.$refs[modal].showModal = true
    },
    closeModal(modal) {
      this.$refs[modal].showModal = false
    },

    startCase(str) {
      return startCase(str)
    },
  },
}
