
export default {
  name: 'BrokersListByService',
  fetchOnServer: false,
  props: {
    filterService: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      brokers: null,
      altLogos: null,
      selectedBroker: null,
      keyword: '',
      servicesArr: [],
    }
  },
  async fetch() {
    try {
      this.loading = true
      let brokers = await fetch(`${this.$config.apiSfUrl}/brokers?$filter=MarketMaker eq false&$expand=BrokerLogoNew&$orderby=BrokerName&sf_culture=${this.$i18n.locale}`).then((res) => res.json())

      let altLogos = await fetch(`${this.$config.apiSfUrl}/brokers?$expand=BrokerLogoNew&$orderBy=BrokerName&$select=Id,BrokerLogoNew&sf_culture=en`).then((res) => res.json())

      //convert services code to text
      brokers.value.forEach((broker) => {
        let membershipBinary = (broker.Services >>> 0).toString(2)
        let reverseBinary = membershipBinary.split('').reverse().join('')
        var membershipLabel = []

        for (var i = 0; i <= reverseBinary.length; i++) {
          if (reverseBinary[i] == '1' && i == reverseBinary.length - 1) {
            membershipLabel.push(this.servicesArr[i])
          } else if (reverseBinary[i] == '1') {
            membershipLabel.push(this.servicesArr[i])
          }
        }
        broker.offeredServices = membershipLabel
      })

      brokers.value.map((broker) => {
        let altLogo = altLogos.value.find((altLogo) => altLogo.Id === broker.Id)
        if (altLogo) {
          broker.LogoAlt = altLogo.BrokerLogoNew
        }
      })

      this.brokers = brokers.value
      this.altLogos = altLogos.value
      this.selectedBroker = brokers.value[0]
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },

  computed: {
    filteredBrokersByServices() {
      if (!this.brokers) {
        return null
      }
      return this.brokers.filter((broker) => broker.offeredServices.includes(this.filterService))
    },

  },
  mounted() {
    if (this.$i18n.locale == 'en') {
      this.servicesArr = this.$servicesArrEn
    } else {
      this.servicesArr = this.$servicesArrAr
    }
  },

  methods: {

    openModal(broker) {
      this.selectedBroker = broker
      this.$refs.brokerModal.showModal = true
    },

    //arabic language api dont have arabic logos, so we switch to english logos if the array dont have values
    verifyLogo(broker) {
      if (!broker) return
      if (broker.BrokerLogoNew.length != 0) {
        return broker.BrokerLogoNew[0].Url.replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
      } else {
        if (broker.LogoAlt.length != 0) {
          var url = broker.LogoAlt[0].Url.replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
          return url
        }
      }
    },
  },
}
