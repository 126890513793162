
import { parse } from 'node-html-parser'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    href: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      imgSrc: null,
    }
  },
  mounted() {
    const root = parse(this.data.LongDescription)
    const img = root.getElementsByTagName('img')
    if (img.length) {
      this.imgSrc = img[0].attributes.src.replace('http://www.dfm.ae', 'https://assets.dfm.ae').replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
    }
  },
  methods: {
    formattedDate(date) {
      return this.$dayjs(date).format('MMMM DD, YYYY')
    },
  },
}
