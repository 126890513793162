import { render, staticRenderFns } from "./membership-options.vue?vue&type=template&id=bfe2eea0&"
import script from "./membership-options.vue?vue&type=script&lang=js&"
export * from "./membership-options.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/membership-options-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fmembers%2Fmembership-options.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/membership-options-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fmembers%2Fmembership-options.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default,VueSimpleAccordion2: require('/vercel/path0/components/VueSimpleAccordion2.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default})
