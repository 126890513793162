
export default {
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/Rectangle_4230_tj2pjg',
    },
    gravity: {
      type: String,
      default: 'center',
    },
  },
  data() {
    return {
      loaded: false,
      modifiers: {
        dpr: 'auto',
        gravity: this.gravity,
      },
    }
  },
  computed: {
    hasTitle() {
      return !!this.$slots.default
    },
  },
  methods: {
    onLoaded() {
      this.loaded = true
    },
  },
}
