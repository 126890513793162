
export default {
  name: 'CircularDetail',
  data() {
    return {
      circular: null,
    }
  },
  async fetch() {
    let circularRes = await fetch(`${this.$config.apiSfUrl}/circulars?$expand=Resources&$filter=id eq ${this.$route.params.id}&sf_culture=${this.$i18n.locale}`).then((res) => res.json())
    this.circular = circularRes.value[0]
  },
  methods: {
    convertDomain(url) {
      return url.replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
    },
  },

}
