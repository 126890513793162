import { render, staticRenderFns } from "./general-meetings.vue?vue&type=template&id=8549496e&"
import script from "./general-meetings.vue?vue&type=script&lang=js&"
export * from "./general-meetings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./general-meetings.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,VueSimpleAccordion: require('/vercel/path0/components/VueSimpleAccordion.vue').default})
