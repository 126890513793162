
import SearchInput from '~/components/SearchInput.vue'
export default {
  name: 'OwnershipHoldingStatistics',
  components: { SearchInput },
  fetchOnServer: false,

  data() {
    return {
      statistics: [],
      keyword: '',
      filterDate: '',
      filterKeyword: '',
      excelFields: {
        Symbol: 'CompanySymbol',
        Origin: 'CountryOrigin',
        'Nationals Owned': {
          field: 'NationalOwned',
          callback: (value) => {
            return `${value}%`
          },
        },
        'GCC Owned': {
          field: 'GCCOwned',
          callback: (value) => {
            return `${value}%`
          },
        },
        'Arabs Owned': {
          field: 'ArabOwned',
          callback: (value) => {
            return `${value}%`
          },
        },
        'Others Owned': {
          field: 'OtherOwned',
          callback: (value) => {
            return `${value}%`
          },
        },
      },
    }
  },
  async fetch() {
    const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `Command=ForeignActualOwnershipInvestments&onDate=${this.filterDate}&Language=${this.$i18n.locale}&type=json`,
    })
    var data = await response.json()
    this.statistics = [].concat(...data) //flattening multi level arrays
  },
  computed: {
    filteredStatistics() {
      if (this.keyword) {
        return this.statistics.filter((item) => {
          return item.CompanySymbol.toLowerCase().includes(this.keyword.toLowerCase())
        })
      } else {
        return this.statistics
      }
    },
  },

  methods: {
    getKeyword(val) {
      this.keyword = val
    },
    dateChanged(val) {
      // console.log(val)
      this.filterDate = val
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDate = ''
    },
    setKeyword(val) {
      this.keyword = val
    },
    totalNonNationals(item) {
      return Number(item.GCCOwned) + Number(item.ArabOwned) + Number(item.OtherOwned)
    },
  },
}
