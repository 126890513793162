
export default {
  name: 'TradeByClientType',
  fetchOnServer: false,

  data: () => ({
    filterDateRange: [],
    selectedDateRange: [],
    tradeClientType: [],
    excelFields: {
      'Client Type': 'name',
      'Net Investment - AED': 'net_investment',
      'Value of stocks sold - AED': 'value_sold',
      'Value of stocks bought': 'value_bought',
    },
  }),
  async fetch() {
    try {
      this.loading = true
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchTradeClient&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()

      let totalInstitutionalInvestment = { name: 'Total Institutional Investment', net_investment: 0, value_sold: 0, value_bought: 0 }
      // add a new object inside data total of all
      data.sections.forEach((element) => {
        if (element.name.toLowerCase() == 'banks' || element.name.toLowerCase() == 'companies' || element.name.toLowerCase() == 'institutions') {
          totalInstitutionalInvestment.net_investment += element.net_investment
          totalInstitutionalInvestment.value_sold += element.value_sold
          totalInstitutionalInvestment.value_bought += element.value_bought
        }
      })
      data.sections.splice(3, 0, totalInstitutionalInvestment)
      data.sections.push({
        name: 'Total',
        net_investment: data.total_net_investment,
        value_sold: data.total_value_sold,
        value_bought: data.total_value_bought,
      })
      this.tradeClientType = data.sections
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    localeLabel() {
      return (name) => {
        if (name.toLowerCase() == 'banks') {
          return this.$t('historical.trade-by-client.data-headers.th5')
        } else if (name.toLowerCase() == 'companies') {
          return this.$t('historical.trade-by-client.data-headers.th6')
        } else if (name.toLowerCase() == 'institutions') {
          return this.$t('historical.trade-by-client.data-headers.th7')
        } else if (name.toLowerCase() == 'total institutional investment') {
          return this.$t('historical.trade-by-client.data-headers.th8')
        } else if (name.toLowerCase() == 'individuals') {
          return this.$t('historical.trade-by-client.data-headers.th9')
        } else if (name.toLowerCase() == 'other') {
          return this.$t('historical.trade-by-client.data-headers.th10')
        } else if (name.toLowerCase() == 'total') {
          return this.$t('historical.trade-by-client.data-headers.th11')
        } else {
          return ''
        }
      }
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
  },
}
