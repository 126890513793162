
export default {
  name: 'TradingSummary',
  fetchOnServer: false,

  data() {
    return {
      tradingSummary: null,
      chartData: null,
      directDeals: null,
    }
  },
  async fetch() {
    try {
      const tradingSummaryResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchProfileTradingTab&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}&toDate=${this.fromDate}&fromDate=${this.toDate}&Period=today`,
      })
      const directDealsResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetDirectDeals&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}&toDate=${this.fromDate}&fromDate=${this.toDate}&Period=today`,
      })

      this.tradingSummary = await tradingSummaryResponse.json()
      this.chartData = JSON.parse(this.tradingSummary.ChartData)
      this.directDeals = await directDealsResponse.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    fromDate() {
      return this.$dayjs().format('DD/MM/YYYY')
    },
    toDate() {
      return this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY')
    },
  },
}
