
export default {
  name: 'ListingEtfsFunds',

  data() {
    return {
      benefits: this.$t('benefits.blocks'),
    }
  },
}
