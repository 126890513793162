
export default {
  name: 'ReportsPresentations',
  fetchOnServer: false,
  data() {
    return {
      annualReports: [],
      quarterlyReports: [],
      presentations: [],
    }
  },
  async fetch() {
    try {
      const annualReports = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=irreports&skip=0&take=100&type=annual&Language=${this.$i18n.locale}`,
      })
      const quarterlyReports = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=irreports&skip=0&take=100&type=quarterly&Language=${this.$i18n.locale}`,
      })
      const presentations = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=irreports&skip=0&take=100&type=presentation&Language=${this.$i18n.locale}`,
      })
      this.annualReports = await annualReports.json()
      this.quarterlyReports = await quarterlyReports.json()
      this.presentations = await presentations.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    earningscall() {
      return this.$t('earnings-call')
    }
  },
}
