
import Meta from '~/mixins/meta'
export default {
  name: 'WhyDfm',
  mixins: [Meta],
  data: function () {
    return {
      route: this.$route,
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
    }
  },
  computed: {
    dfmFeatures() {
      return this.$t('dfm-features.features')
    },
    keyFeatures() {
      return this.$t('key-features.features')
    },
  },
  mounted() {
    this.$route
  },
}
