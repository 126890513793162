

export default {
  name: 'IvestorNotificationForm',
  data() {
    return {
      form: {
        FormId: 'e3fd97f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: 'a80498f8-f602-6339-b0d9-ff00009be840',
        FullName: '',
        EmailTextFieldController: '',
        DeviceType: '',
        AcceptTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      showError: false,
      submittingForm: false,
    }
  },
  computed: {
    CountryOfResidence() {
      return this.$refs['country-dropdown'].selectedCountry
    },
    Nationality() {
      return this.$refs['nationality-dropdown'].selectedCountry
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        // // console.log('ReCaptcha token:', token)
        const formData = new FormData()
        formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('FullName', this.form.FullName), formData.append('EmailTextFieldController', this.form.EmailTextFieldController), formData.append('CountryOfResidence', this.CountryOfResidence), formData.append('Nationality', this.Nationality), formData.append('DeviceType', this.form.DeviceType), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/ivestornotificationform', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 5000)
        } else {
          // this.showError = true
          // setTimeout(() => {
          //   this.showError = false
          //   this.resetForm()
          //   this.formSubmitted()
          // }, 5000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.FullName = ''
      this.form.EmailTextFieldController = ''
      this.form.DeviceType = ''
      this.form.AcceptTerms = false
      this.$recaptcha.destroy()
    },
  },
}
