
export default {
  props: {
    type: {
      type: String,
      default: 'pdf',
    },
    href: {
      type: String,
      default: '#',
    },
  },
}
