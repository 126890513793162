
export default {
  name: 'BrokersList',
  fetchOnServer: false,
  data() {
    return {
      brokers: null,
      filteredBrokersByServices: null,
      altLogos: null,
      selectedBroker: null,
      keyword: '',
      filterService: '',
      servicesArr: [],
    }
  },
  async fetch() {
    try {
      let brokers = await fetch(`${this.$config.apiSfUrl}/brokers?$filter=MarketMaker eq false&$expand=BrokerLogoNew&$orderby=BrokerName&sf_culture=${this.$i18n.locale}`).then((res) => res.json())

      let altLogos = await fetch(`${this.$config.apiSfUrl}/brokers?$expand=BrokerLogoNew&$orderBy=BrokerName&$select=Id,BrokerLogoNew&sf_culture=en`).then((res) => res.json())

      //convert services code to text
      brokers.value.forEach((broker) => {
        let membershipBinary = (broker.Services >>> 0).toString(2)
        let reverseBinary = membershipBinary.split('').reverse().join('')
        var membershipLabel = []

        for (var i = 0; i <= reverseBinary.length; i++) {
          if (reverseBinary[i] == '1' && i == reverseBinary.length - 1) {
            membershipLabel.push(this.servicesArr[i])
          } else if (reverseBinary[i] == '1') {
            membershipLabel.push(this.servicesArr[i])
          }
        }
        broker.offeredServices = membershipLabel
      })

      brokers.value.map((broker) => {
        let altLogo = altLogos.value.find((altLogo) => altLogo.Id === broker.Id)
        if (altLogo) {
          broker.LogoAlt = altLogo.BrokerLogoNew
        }
      })

      this.brokers = brokers.value
      this.filteredBrokersByServices = brokers.value
      this.altLogos = altLogos.value
      this.selectedBroker = brokers.value[0]
    } catch (error) {
      // console.log(error)
    }
  },

  computed: {
    filteredBrokers: function () {
      return this.filteredBrokersByServices.filter((broker) => {
        return broker.BrokerName.toLowerCase().includes(this.keyword) || broker.BrokerID.toLowerCase().includes(this.keyword)
      })
    },
    servicesDropdownList: function () {
      return this.$t('servicesDropdownList')
    },
  },
  mounted() {
    if (this.$i18n.locale == 'en') {
      this.servicesArr = this.$servicesArrEn
    } else {
      this.servicesArr = this.$servicesArrAr
    }
  },

  methods: {
    getKeyword(val) {
      this.keyword = val.toLowerCase()
    },
    filterBrokersByServices() {
      if (this.filterService == '') {
        this.filteredBrokersByServices = this.brokers
        return
      }
      this.filteredBrokersByServices = this.brokers.filter((broker) => {
        return broker.offeredServices.includes(this.filterService)
      })
    },
    clearFilter() {
      this.filterService = ''
      this.filteredBrokersByServices = this.brokers
    },
    openModal(broker) {
      this.selectedBroker = broker
      this.$refs.brokerModal.showModal = true
    },

    //arabic language api dont have arabic logos, so we switch to english logos if the array dont have values
    verifyLogo(broker) {
      if (!broker) return
      if (broker.BrokerLogoNew.length != 0) {
        return broker.BrokerLogoNew[0].Url.replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
      } else {
        if (broker.LogoAlt.length != 0) {
          var url = broker.LogoAlt[0].Url.replace('http://assets.dfm.ae', 'https://assets.dfm.ae')
          return url
        }
      }
    },
  },
}
