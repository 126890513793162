
export default {
  name: 'BrokerDetails',

  props: {
    broker: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    convertDate(date) {
      const d = new Date(date)
      return d.toLocaleDateString()
    },
    currentStatus(status) {
      if (status === '1') {
        return this.$t('commons.active')
      }
      if (status === '2') {
        return this.$t('commons.under-cancellation')
      }
      if (status === '3') {
        return this.$t('commons.cancelled')
      }
      return '--'
    },
  },
}
