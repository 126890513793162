
export default {
  props: {
    tableId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'Excel Download.csv',
    },
  },
  methods: {
    download_csv(csv, filename) {
      var csvFile
      var downloadLink

      // CSV FILE
      csvFile = new Blob([csv], { type: 'text/csv' })

      // Download link
      downloadLink = document.createElement('a')

      // File name
      downloadLink.download = filename

      // We have to create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile)

      // Make sure that the link is not displayed
      downloadLink.style.display = 'none'

      // Add the link to your DOM
      document.body.appendChild(downloadLink)

      // Lanzamos
      downloadLink.click()
    },

    export_table_to_csv(html, filename) {
      var csv = []
      var rows = document.querySelectorAll(`#${this.tableId} tr`)

      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll('td, th')

        for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText)

        csv.push(row.join('\t'))
      }

      // Download CSV
      this.download_csv(csv.join('\n'), filename)
    },
    exportToExcel() {
      // console.log(this.$parent.$refs[this.tableId].outerHTML)
      this.export_table_to_csv(this.$parent.$refs[this.tableId].outerHTML, this.name)
    },
  },
}
