
export default {
  name: 'MarketdataBulletins',

  computed: {
    bulletinSrc() {
      return this.$i18n.locale === 'en' ? 'https://service.dfm.ae/Bulletins?L=0' : 'https://service.dfm.ae/Bulletins?L=1'
    },
  },
  methods: {
    resizeBulletin() {
      this.$refs.bulletins.style.height = this.$refs.bulletins.contentWindow.document.body.scrollHeight + 'px'
    },
  },
}
