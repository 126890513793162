
export default {
  data() {
    return {
      unclaimedDividends: [],
      nin: '',
      securityCode: '',
      showError: false,
      loading: false,
    }
  },

  methods: {
    async fetchUnclaimedDividends() {
      this.showError = false
      try {
        const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `Command=irunclaimeddividends&nin=${this.nin}`,
        })
        this.unclaimedDividends = await response.json()
        this.showError = this.unclaimedDividends.length == 0
        this.loading = false
      } catch (error) {
        // console.log(error)
      }
    },

    $fetch() {
      this.loading = true
      this.fetchUnclaimedDividends()
    },
  },
}
