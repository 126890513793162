
export default {
  props: {
    alignRight: {
      type: Boolean,
    },
    label: {
      type: String,
      default: 'Select',
    },
    data: {
      type: Array,
      default: () => ['lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs', 'lorem ipsum item sdfsd dfsdfs sdfs fdfs'],
      required: true,
    },
  },
  data() {
    return {
      isListOpen: false,
      listFilterKeyword: '',
      modifiedData: [],
      showselectedData: false,
    }
  },

  computed: {
    selectedData() {
      let arr = []
      this.modifiedData.forEach((val) => {
        if (val.selected) {
          arr.push(val.name)
        }
      })
      return arr
    },
    filteredData() {
      if (!this.selectedData.length) {
        this.showselectedData = false
      }
      if (this.showselectedData) {
        return this.modifiedData.filter((item) => {
          return item.name.toString().toLowerCase().includes(this.listFilterKeyword.toLowerCase()) && item.selected == this.showselectedData
        })
      } else {
        return this.modifiedData.filter((item) => {
          return item.name.toString().toLowerCase().includes(this.listFilterKeyword.toLowerCase())
        })
      }
    },
  },
  watch: {
    selectedData: function () {
      this.$emit('get-values', this.selectedData)
    },
  },
  mounted() {
    this.$emit('get-values', this.selectedData)
    this.data.forEach((item) => {
      this.modifiedData.push({
        name: item,
        selected: false,
      })
    })
  },
  methods: {
    selectItem(item) {
      item.selected = !item.selected
    },
    deleteSelectedData() {
      this.showselectedData = false
      Object.values(this.modifiedData).forEach((val) => {
        val.selected = false
      })
    },
    closeListBox() {
      this.isListOpen = false
      this.listFilterKeyword = ''
    },
  },
}
