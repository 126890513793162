
export default {
  name: 'AttachmentListDropdown',
  props: {
    resources: {
      type: Array,
      required: true,
    },
    company: {
      type: String,
      default: 'dfm', // or nasdaq
    },
  },
  computed: {
    resourcePath() {
      if (this.company == 'dfm') {
        return this.$config.dfmResourcePath
      } else {
        return this.$config.ndResourcePath
      }
      return ''
    },
  },
}
