
export default {
  name: 'DisclosureDetail',
  fetchOnServer: false,
  name: 'DisclosuresDetail',
  data() {
    return {
      marketAnnouncement: null,
    }
  },

  async fetch() {
    try {
      const res = await this.$axios.get(`${this.$config.apiEfsahUrl}/detail`, {
        params: {
          id: this.$route.params.id,
          lang: this.$i18n.locale,
        },
      })

      this.marketAnnouncement = res.data.root[0]
    } catch (error) {
      // console.log(error)
    }
  },
}
