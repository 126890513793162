
export default {
  name: 'HistoricalDataDFMGI',
  fetchOnServer: false,

  data: () => ({
    loading: true,
    filterDateRange: [],
    selectedDateRange: [],
    dfmgi: null,
    stats: null,
    chart: null,
    yearlyClosing: null,
    dailyClosing: null,
    excelFields: {
      Date: 'd',
      'DFM Index': 'i',
      Change: 'c',
      'Chage %': 'cp',
    },
  }),
  async fetch() {
    try {
      this.loading = true
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchDFMIndex&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()
      this.dfmgi = data
      this.stats = data.Stats
      this.chart = data.chart
      this.yearlyClosing = data.yearly_closing
      this.dailyClosing = data.daily_closing
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    modifiedDailyClosing() {
      if (!this.dailyClosing.length && this.loading) return
      return this.dailyClosing.map((item) => {
        let TrendClass = item.c > 0 ? 'increase' : item.c < 0 ? 'decrease' : 'nocrease'
        return {
          ...item,
          ChangeTrendClass: TrendClass,
        }
      })
    },
    chartData() {
      if (this.loading) return
      return this.chart.points.map((point) => {
        return {
          date: this.$dayjs(point.Item1).format('YYYY-MM-DD'),
          currentIndex: point.Item2,
        }
      })
    },
  },
  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
