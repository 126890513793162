
export default {
  name: 'IpoListingsOverview',
  data() {
    return {
      benefits: this.$t('section1.blocks'),
      markets: this.$t('markets.blocks'),
    }
  },
}
