
import Meta from '~/mixins/meta'

export default {
  name: 'DfmCareers',
  mixins: [Meta],
  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      values: [
        {
          title: 'Diligence',
          content: 'We work with care and rigour. The details make the difference.',
        },
        {
          title: 'Openness',
          content: 'We are aware that the world is constantly changing around us. We seek out new and different ideas, opinions, and points of view.',
        },
        {
          title: 'Collaboration',
          content: 'We are in this together, working hand-in-hand for growth and prosperity. When our clients thrive, we thrive, too.',
        },
        {
          title: 'Determination',
          content: 'We approach our work with the end goal in mind and have a relentless spirit that ensures results.',
        },
        {
          title: 'Proactivity',
          content: 'We take actions intended to cause change, rather than reacting to change.',
        },
      ],
    }
  },
  watch: {
    $route() {
      this.updateRoute()
    },
  },
  mounted() {
    this.updateRoute()
  },
  methods: {
    updateRoute() {
      if (this.$route.name == this.localeRoute('discover-dfm-news-media').name) {
        this.$router.replace(
          this.localePath({
            name: 'discover-dfm-news-media-press-releases',
          })
        )
      }
    },
  },
}
