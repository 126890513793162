
export default {
  head() {
    return {
      title: `${this.meta.title}`,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: `${this.meta.title}`,
        },
        {
          hid: 'description',
          name: 'description',
          content: `${this.meta.description}`,
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: `${this.meta.title}`,
        },
        {
          hid: 'og-type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: `${this.meta.description}`,
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: `https://www.dfm.ae/${this.$route.path}`,
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: `${this.meta.image}`,
        },
        {
          hid: 'twitter-title',
          property: 'twitter:title',
          content: `${this.meta.title}`,
        },
        {
          hid: 'twitter-description',
          property: 'twitter:description',
          content: `${this.meta.description}`,
        },
        {
          hid: 'twitter-image',
          property: 'twitter:image',
          content: `${this.meta.image}`,
        },
        {
          hid: 'twitter-card',
          property: 'twitter:card',
          content: 'summary',
        },
      ],
      noscript: [{ innerHTML: 'Body No Scripts', body: true }],
    }
  },
}
