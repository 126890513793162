
export default {
    props: {
        partner: {
            type: Object,
            required: true,
        },
        inverse: {
            type: Boolean,
            default: false,
        },
    },
}
