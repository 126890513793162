
export default {
  name: 'IpoSubscriptions',
  fetchOnServer: false,
  data() {
    return {
      upcomingIpos: [],
      video1: {
        videoTitle: 'IPO Subscriptions via DFM App',
        videoId: '787488635',
        videoThumb: 'visuals/dfm-visual-8_g0dosf',
      },
      video2: {
        videoTitle: 'IPO Subscriptions via IPO platform',
        videoId: '787488676',
        videoThumb: 'visuals/dfm-visual-9_icevcm',
      },
      faqs: this.$t('faqs'),
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetUpcomingIPO&Language=${this.$i18n.locale}`,
      })
      var res = await response.json()
      this.upcomingIpos = res.Body
      console.log(this.upcomingIpos)
    } catch (error) {
      // console.log(error)
    }
  },
}
