
export default {
  name: 'SecurityReports',
  fetchOnServer: false,
  props: {
    command: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      default: null,
    },
    reportType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reports: [],
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=${this.command}&Period=custom&Company=${this.symbol}&Language=${this.$i18n.locale}`,
      })
      this.reports = await response.json()
      this.$emit('fetchCompleted')
    } catch (error) {
      // console.log(error)
    }
  },
}
