
export default {
  name: 'ListedSecurities',
  data() {
    return {
      tabs: [
        {
          title: this.$t('commons.equities'),
          href: '/the-exchange/market-information/listed-securities/equities/',
        },
        {
          title: this.$t('commons.sukuk'),
          href: '/the-exchange/market-information/listed-securities/sukuk/',
        },
        {
          title: this.$t('commons.bonds'),
          href: '/the-exchange/market-information/listed-securities/bonds/',
        },
        {
          title: this.$t('commons.funds'),
          href: '/the-exchange/market-information/listed-securities/funds/',
        },
        {
          title: this.$t('commons.etfs'),
          href: '/the-exchange/market-information/listed-securities/exchange-traded-funds/',
        },
        {
          title: this.$t('commons.reits'),
          href: '/the-exchange/market-information/listed-securities/real-estate-investment-trust/',
        },
      ],
    }
  },
  watch: {
    $route() {
      if (this.$route.name == this.localeRoute('the-exchange-market-information-listed-securities').name) {
        this.$router.replace(
          this.localePath({
            name: 'the-exchange-market-information-listed-securities-equities',
          })
        )
      }
    },
  },
  mounted() {
    if (this.$route.name == this.localeRoute('the-exchange-market-information-listed-securities').name) {
      this.$router.replace(
        this.localePath({
          name: 'the-exchange-market-information-listed-securities-equities',
        })
      )
    }
  },
}
