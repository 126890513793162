
import Popper from 'vue-popperjs'
import '~/assets/css/popper.css'
export default {
  name: 'OwnershipHoldingStatistics',
  components: { popper: Popper },
  fetchOnServer: false,

  data() {
    return {
      foreignownerships: [],
      keyword: '',
      filterDate: '',
      filterKeyword: '',
      popperOptions: {
        placement: 'top',
        modifiers: { offset: { offset: '0,10px' } },
      },
      excelFields: {
        Symbol: 'CompanySymbol',
        Origin: 'CountryOrigin',
        'Nationals Permitted': 'NationalPermitted',
        'Nationals Actual': 'NationalOwned',
        'GCC Permitted': 'GCCPermitted',
        'GCC Actual': 'GCCOwned',
        'Others Permitted': 'OtherPermitted',
        'Others Actual': 'OtherOwned',
      },
    }
  },
  async fetch() {
    const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `Command=ForeignOwnershipInvestmentsNew&onDate=${this.filterDate}&Language=${this.$i18n.locale}&type=json`,
    })
    var data = await response.json()
    this.foreignownerships = [].concat(...data) //flattening multi level arrays
  },
  computed: {
    filteredStatistics() {
      if (this.keyword) {
        return this.foreignownerships.filter((item) => {
          return item.CompanySymbol.toLowerCase().includes(this.keyword.toLowerCase())
        })
      } else {
        return this.foreignownerships
      }
    },
  },

  methods: {
    convertData(data) {
      if (data === 'N/A' || data === '0') {
        return data
      }
      else if (data === '') {
        return 'N/A'
      }
      return this.$options.filters.priceDecimal(data, 2) + '%';

    },
    setKeyword(val) {
      this.keyword = val
    },
    dateChanged(val) {
      // console.log(val)
      this.filterDate = val
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDate = ''
    },

    totalNonNationals(item) {
      return Number(item.GCCOwned) + Number(item.ArabOwned) + Number(item.OtherOwned)
    },
  },
}
