
export default {
  name: 'CircularsList',

  fetchOnServer: false,
  data() {
    return {
      circulars: null,
    }
  },
  async fetch() {
    let circulars = await fetch(`${this.$config.apiSfUrl}/circulars?$expand=resources&$top=5&$orderby=CircularDate desc&sf_culture=${this.$i18n.locale}`).then((res) => res.json())
    this.circulars = circulars.value
  },

}
