
export default {
  name: 'SecuritySymbol',
  fetchOnServer: false,

  // scrollToTop: true,
  data() {
    return {
      subTabs: [
        {
          title: this.$t('data-headers.trading'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`,
          exclude: ['sukuks', 'bonds', 'funds'],
        },
        {
          title: this.$t('data-headers.profile'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/profile/`,
        },
        {
          title: this.$t('data-headers.news-disclosures'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/news-disclosures/`,
        },
        {
          title: this.$t('data-headers.reports'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/reports/`,
        },
        {
          title: this.$t('data-headers.general-meetings'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/general-meetings/`,
          exclude: ['sukuks', 'bonds', 'funds'],
        },
        {
          title: this.$t('data-headers.corporate-actions'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/corporate-actions/`,
          exclude: ['sukuks', 'bonds', 'funds'],
        },
      ],
      tradingSummary: null,
      companyInfo: null,
    }
  },

  async fetch() {
    try {
      const tradingSummaryRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchProfileTradingTab&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}&toDate=${this.fromDate}&fromDate=${this.toDate}&Period=today`,
      })

      const companyInfoRes = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=companyprofile&lang=${this.$i18n.locale}&symbol=${this.$route.params.SecuritySymbol}`,
      })

      this.tradingSummary = await tradingSummaryRes.json()
      this.companyInfo = await companyInfoRes.json()
      // switch to appropriate
      if (this.$route.name == this.localeRoute(`/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/`).name) {
        // console.log(this.isTradeable)
        if (this.isTradeable) {
          this.$router.replace(
            this.localePath({
              path: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`,
            })
          )
        } else {
          this.$router.replace(
            this.localePath({
              path: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/profile/`,
            })
          )
        }
      }
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    allTabs() {
      return [
        {
          title: this.$t('data-headers.trading'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`,
          exclude: ['sukuks', 'bonds', 'funds'],
        },
        {
          title: this.$t('data-headers.profile'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/profile/`,
        },
        {
          title: this.$t('data-headers.news-disclosures'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/news-disclosures/`,
          hide: this.isLinked
        },
        {
          title: this.$t('data-headers.reports'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/reports/`,
          hide: this.isLinked
        },
        {
          title: this.$t('data-headers.general-meetings'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/general-meetings/`,
          exclude: ['sukuks', 'bonds', 'funds'],
          hide: this.isLinked
        },
        {
          title: this.$t('data-headers.corporate-actions'),
          href: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/corporate-actions/`,
          exclude: ['sukuks', 'bonds', 'funds'],
          hide: this.isLinked
        },
      ]
    },
    isLinked() {
      if (!this.companyInfo) {
        return false
      }
      return this.companyInfo.IsLinked
    },
    fromDate() {
      return this.$dayjs().format('DD/MM/YYYY')
    },
    toDate() {
      return this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY')
    },
    tabs() {
      if (!this.companyInfo) return []
      return this.allTabs.filter((tab) => {
        return !tab.exclude || !tab.exclude.includes(this.companyInfo.InstrumentType)
      })
    },
    isTradeable() {
      switch (this.companyInfo.InstrumentType) {
        case 'sukuks':
          return false
        case 'bonds':
          return false
        case 'funds':
          return false
        default:
          return true
      }
    },
    isSukuk() {
      return this.companyInfo.InstrumentType == 'sukuks'
    },
    isBond() {
      return this.companyInfo.InstrumentType == 'bonds'
    },
    isFund() {
      return this.companyInfo.InstrumentType == 'funds'
    },
  },
  watch: {
    $route() {
      if (this.$route.name == this.localeRoute(`/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/`).name) {
        this.$router.replace(
          this.localePath({
            path: `/the-exchange/market-information/company/${this.$route.params.SecuritySymbol}/trading/`,
          })
        )
      }
    },
  },
  mounted() { },
  methods: {
    convertDomain(url) {
      if (!url) {
        this.companyInfo.InstrumentType == 'sukuk' ? (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/sukuk-logo_n6ovab') : this.companyInfo.InstrumentType == 'bonds' ? (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/bond-logo_py7tel') : (url = 'https://res.cloudinary.com/dubaifinancialmarket/image/upload/v1674024285/images/fund-logo_fpxpd1')
        return url
      }
      url = url.replace('https://www.dfm.ae', this.$config.assetsDomain)
      return url
    },
  },
}
