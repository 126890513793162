
export default {
  props: {
    email: {
      type: String,
    },
  },
  computed: {
    emailValue() {
      return this.$slots.default[0].text
    },
  },
}
