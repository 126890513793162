
// add no-icon attribute to disable icons
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    company: {
      type: String,
      default: 'dfm', // or nasdaq
    },
    type: {
      type: String,
      default: 'pdf',
    },
    noAttachment: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    noDetail: {
      type: Boolean,
      default: false,
    },
  },
}
