
import _some from 'lodash/some'
import _filter from 'lodash/filter'

export default {
  name: 'SecuritiesSukuk',
  fetchOnServer: false,
  data() {
    return {
      filterKeyword: '',
      filterExchange: [],
      showMaturedSecurities: false,
      securities: [],
    }
  },
  async fetch() {
    try {
      const rawResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=FreshSecuritiesLists&Language=${this.$i18n.locale}&securitytype=sukuks`,
      })
      this.securities = await rawResponse.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    filteredSecurities() {
      var result = this.securities.filter((security) => {
        if (security.FullName != null) {
          return security.FullName.toLowerCase().includes(this.filterKeyword.toLowerCase())
        }
      })
      if (this.securities.length) {
        result = result.filter((security) => {
          return security.IsMatured == this.showMaturedSecurities
        })
      }
      if (this.filterExchange.length) {
        result = result.filter((security) => {
          return this.filterExchange.includes(security.Exchange)
        })
      }

      if (this.showMaturedSecurities) {
        result = result.filter((security) => {
          return security.IsMatured == this.showMaturedSecurities
        })
      }

      return result
    },
  },
  methods: {
    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
    getExchanges(exchanges) {
      this.filterExchange = exchanges
    },
  },
}
