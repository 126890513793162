
export default {
  name: 'WeeklyMarginTradingReport',
  fetchOnServer: false,

  data() {
    return {
      marginTrading: null,
      keyword: '',
      filterDate: this.$dayjs().format('YYYY-MM-DD'),
      filterKeyword: '',
      excelFields: {
        Symbol: 'Symbol',
        'Available Shares': 'AvailableShares',
        'Total purchases during the week - by Trades': 'TradeCount_Buy',
        'Total purchases during the week - by Volume': 'TradeVolume_Buy',
        'Total purchases during the week - by Value': 'TradeValue_Buy',
        'Total sales during the week - by Trades': 'TradeCount_Sell',
        'Total sales during the week - by Volume': 'TradeVolume_Sell',
        'Total sales during the week - by Value': 'TradeValue_Sell',
      },
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=getweeklymargin&from=${this.filterDate}&type=json`,
      })
      this.marginTrading = await response.json()
      // console.log(this.marginTrading)
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    weekMin() {
      return this.$dayjs(this.marginTrading.Records[0].Balance_Date).weekday(1).format('DD/MM/YYYY')
    },
    weekMax() {
      return this.$dayjs(this.marginTrading.Records[0].Balance_Date).weekday(5).format('DD/MM/YYYY')
    },
  },
  mounted() {
    this.filterDate = this.$dayjs().format('YYYY-MM-DD')
    // console.log(this.filterDate)
  },

  methods: {
    getKeyword(val) {
      this.keyword = val
    },
    dateChanged(val) {
      // console.log(val)
      this.filterDate = this.$dayjs(val, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDate = ''
    },
    setKeyword(val) {
      this.keyword = val
    },
    totalNonNationals(item) {
      return Number(item.GCCOwned) + Number(item.ArabOwned) + Number(item.OtherOwned)
    },
  },
}
