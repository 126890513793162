import { render, staticRenderFns } from "./exchange-traded-funds.vue?vue&type=template&id=37448242&"
import script from "./exchange-traded-funds.vue?vue&type=script&lang=js&"
export * from "./exchange-traded-funds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchInput: require('/vercel/path0/components/SearchInput.vue').default,FilterExchange: require('/vercel/path0/components/FilterExchange.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,SecurityCard: require('/vercel/path0/components/SecurityCard.vue').default})
