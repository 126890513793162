import { render, staticRenderFns } from "./_Id.vue?vue&type=template&id=6c3da8af&scoped=true&"
import script from "./_Id.vue?vue&type=script&lang=js&"
export * from "./_Id.vue?vue&type=script&lang=js&"
import style0 from "./_Id.vue?vue&type=style&index=0&id=6c3da8af&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3da8af",
  null
  
)

/* custom blocks */
import block0 from "./_Id.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default})
