
export default {
  props: {
    alt: {
      type: String,
      default: 'Image',
    },
    title: {
      type: String,
      default: 'Image',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      default: 'visuals/dfm-visual-6_b308gh.jpg',
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: null,
    },
    decorator: {
      type: Boolean,
      default: false,
    },
    decoratorColor: {
      type: String,
      default: 'text-brand-green',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    imageCrop: {
      type: String,
      default: 'fit',
    },
    sizes: {
      type: String,
      default: 'sm:400px md:800px lg:1200px',
    },
  },
  data() {
    return {
      modifiers: {
        dpr: 'auto',
      }
    }
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
  },
}
