
import { Glide, GlideSlide } from 'vue-glide-js'
// https://github.com/mengxiong10/vue2-datepicker
import DatePicker from 'vue2-datepicker'
import '~/assets/css/datepicker.css'
export default {
  name: 'MarketAnnouncements',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    DatePicker,
  },
  fetchOnServer: false,
  data() {
    return {
      loading: false,
      latestDisclosures: [],
      disclosures: [],
      disclosuresCount: 0,
      filterStatus: false,
      filterYear: '',
      filterDateRange: [],
      filterDateRangeTitle: [],
      filterKeyword: '',
      lastFilterKeyword: '',
      itemsToGet: 20,
      currentPage: 1,
      filterAnnouncement: {},
      selectedAnnouncementTypeTitle: '',

      breakpoints: {
        800: {
          gap: 24,
          perView: 2.2,
        },
        600: {
          gap: 10,
          perView: 1.2,
        },
      },
    }
  },
  async fetch() {
    try {
      const latestDisclosures = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah`, {
        params: {
          lang: this.$i18n.locale,
          h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
          announcement_type: 'DFM News, Derivative News',
          cms_resources: true,
          take: 4,
        },
      })

      this.latestDisclosures = latestDisclosures.data.root
      this.selectedAnnouncementTypeTitle = this.filterAnnouncement.label
    } catch (error) {
      this.loading = false
      // console.log(error)
    }
  },

  mounted() {
    this.fetchDisclosures(false)
  },

  beforeDestroy() {
    if (this.glide) this.glide.destroy()
  },

  methods: {
    async fetchDisclosures(status) {
      if (status) {
        this.currentPage = 1
      }
      let apiOptions = {
        params: {
          lang: this.$i18n.locale,
          h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
          take: this.itemsToGet,
          skip: (this.currentPage - 1) * this.itemsToGet,
          from: this.filterDateRange[0],
          to: this.filterDateRange[1],
          announcement_type: this.filterAnnouncement.value,
          keyword: this.filterKeyword,
          cms_resources: true,
        },
      }
      this.loading = true
      const disclosures = await this.$axios.get(`${this.$config.apiEfsahUrl}/prototype_efsah`, apiOptions)

      const disclosuresCount = await this.$axios.get(`${this.$config.apiEfsahUrl}/efsah_count`, {
        params: {
          lang: this.$i18n.locale,
          h7_datetime_format: 'MMM dd, yyyy HH:mm:ss',
          from: this.filterDateRange[0],
          to: this.filterDateRange[1],
          announcement_type: 'DFM News, Derivative News',
          keyword: this.filterKeyword,
          cms_resources: true,
        },
      })
      this.disclosures = disclosures.data.root
      this.selectedAnnouncementTypeTitle = this.filterAnnouncement.label
      this.disclosuresCount = disclosuresCount.data.root[0].count
      this.filterStatus = status
      this.lastFilterKeyword = this.filterKeyword
      this.filterDateRangeTitle = this.filterDateRange
      // this.filterKeyword = ''
      this.$refs.filterInput.filterKeyword = ''

      //Waste half a second seconds
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
    clearSearch() {
      this.filterDateRange = []
      this.filterDateRangeTitle = []
      this.filterStatus = false
      this.fetchDisclosures(false)
      this.currentPage = 1
    },
    updatePagination(page) {
      this.currentPage = page
      const myEl = this.$refs.disclosures
      this.$smoothScroll({
        scrollTo: myEl,
      })
      setTimeout(() => {
        this.fetchDisclosures(false)
      }, 500)
    },
    notAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    },
    getAnnouncements(announcement) {
      console.log("announcement fired")
      this.filterAnnouncement = announcement
    },
  },
}
