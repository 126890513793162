
export default {
  name: 'SecuritiesEquities',
  fetchOnServer: false,
  data() {
    return {
      filterKeyword: '',
      filterExchange: [],
      filterSector: [],
      filterForeignOwnership: false,
      filterIndexedSymbol: false,
      showDelisted: false,
      filterListingYear: '',
      securities: [],
    }
  },
  async fetch() {
    try {
      const rawResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=FreshSecuritiesLists&Language=${this.$i18n.locale}&securitytype=equities`,
      })
      this.securities = await rawResponse.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    sectors() {
      let arr = this.securities.map((item) => item.Sector)
      var sectorArray = [...new Set(arr)]
      // // console.log(sectorArray)
      //return array without value empty
      return sectorArray.filter((el) => el != '')
    },
    currentYear() {
      return this.$dayjs().format('YYYY')
    },
    listingYears() {
      let arr = []
      let year = this.currentYear
      while (year >= 2000) {
        arr.push(year)
        year--
      }
      return arr
    },
    filteredSecurities() {
      var result = this.securities.filter((security) => {
        return security.FullName.toLowerCase().includes(this.filterKeyword.toLowerCase()) || security.SecuritySymbol.toLowerCase().includes(this.filterKeyword.toLowerCase())
      })
      if (this.securities.length) {
        result = result.filter((security) => {
          return security.Active == !this.showDelisted
        })
      }
      if (this.filterExchange.length) {
        result = result.filter((security) => {
          return this.filterExchange.includes(security.Exchange)
        })
      }
      if (this.filterSector.length) {
        result = result.filter((security) => {
          return this.filterSector.includes(security.Sector)
        })
      }
      if (this.filterForeignOwnership) {
        result = result.filter((security) => {
          return security.ForeignOwnershipAllowed
        })
      }
      if (this.filterIndexedSymbol) {
        result = result.filter((security) => {
          return security.IndexedSymbol
        })
      }

      if (this.filterListingYear) {
        result = result.filter((security) => {
          return this.filterListingYear.includes(security.ListingYear)
        })
      }

      return result
    },
  },
  methods: {
    setKeyword(keyword) {
      this.filterKeyword = keyword
    },
    getExchanges(exchanges) {
      this.filterExchange = exchanges
    },
  },
}
