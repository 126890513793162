
import DatePicker from 'vue2-datepicker'
import '~/assets/css/datepicker.css'

export default {
  name: 'DateRange',
  components: {
    DatePicker,
  },
  props: {
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  data: () => ({
    dateRange: [],
  }),
  created() {
    this.dateRange = [this.$dayjs().subtract(3, 'month').format(this.format), this.$dayjs().format(this.format)]
    this.$parent.filterDateRange = this.dateRange
  },

  methods: {
    dateChanged() {
      this.$emit('dateChanged', this.dateRange)
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
  },
}
