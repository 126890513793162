
export default {
  props: {
    byKey: {
      type: String,
      default: 'totalvolume',
    },
    sortType: {
      type: String,
      default: 'desc',
    },
    stockData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    stocks() {
      return this.stockData
    },
    loadPlaceholder() {
      return this.stockData && this.stockData.length === 0
    },
    sortByValue() {
      let field = this.byKey
      if (this.sortType === 'desc') {
        return this.stocks.slice().sort((a, b) => {
          return b[field] - a[field]
        })
      } else {
        return this.stocks.slice().sort((a, b) => {
          return a[field] - b[field]
        })
      }
    },
  },
}
