
export default {
  name: 'DailySummary',
  fetchOnServer: false,

  data() {
    return {
      dailySummary: null,
      dataHeaders: [
        'Date',
        'Open',
        'High',
        'Low',
        'Trades',
        'Volume',
        'Value',
        'Last',
        'Previous',
        'Closing',
        'Change',
        'ChangePercentage'
      ],
      excelFields: {
        Date: 'Date',
        Open: 'Open',
        High: 'High',
        Low: 'Low',
        Trades: 'Trades',
        Volume: 'Volume',
        Value: 'Value',
        Last: 'Last',
        Previous: 'Previous',
        Change: 'Change',
        'Change Percentage': 'ChangePercentage'
      },
    }
  },
  async fetch() {
    try {
      const dailySummaryResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchProfileDailyTab&Language=${this.$i18n.locale}&Company=${this.$route.params.SecuritySymbol}&toDate=${this.fromDate}&fromDate=${this.toDate}&Period=custom`,
      })
      this.dailySummary = await dailySummaryResponse.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    fromDate() {
      return this.$dayjs().format('DD/MM/YYYY')
    },
    toDate() {
      return this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY')
    },
  },
  mounted() {
    // // console.log(this.$route.params.SecuritySymbol)
  },
}
