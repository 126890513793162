
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    inverse: undefined,
    size: {
      type: String,
      default: 'md',
    },
  },
  data() {
    return {
      selectedIndex: '0', // the index of the selected tab,
    }
  },
  computed: {
    availableTabs() {
      return this.tabs.filter(tab => !tab.hide)
    }
  },
  mounted() {
    // Make sure the correct option is shown on load
    // get currewnt path without the language pref
    const path = this.$route.path.replace('/en', '').replace('/ar', '')
    this.tabs.filter((tab, index) => {
      if (tab.href === path) {
        this.selectedIndex = index
      }
    })
  },

  methods: {
    onChange(e) {
      if (e.target.options[this.selectedIndex].dataset.external) {
        window.open(e.target.options[this.selectedIndex].dataset.href, '_blank')
        return
      }
      this.$router.push(this.localePath({ path: e.target.options[this.selectedIndex].dataset.href }))
    },
    changeIndex(index) {
      this.selectedIndex = index
    },
  },
}
