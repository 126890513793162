
import Meta from '~/mixins/meta'

export default {
  mixins: [Meta],
  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      managements: [],
      directors: [],
      shariaboard: [],
      selected: null,
      selectedType: 'management',
      keyword: '',
      pageNavSections: [
        {
          title: this.$t('bod.title'),
          id: 'boardofdirectors',
        },
        {
          title: this.$t('sharia.title'),
          id: 'sharia',
        },
        {
          title: this.$t('management.title'),
          id: 'management',
        },
      ],
    }
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const [management, directors, shariaboard] = await Promise.all([fetch(`${this.$config.apiSfUrl}/directors?$filter=dfmorganizations/any(x:x%20eq%204B8C04F8-F602-6339-B0D9-FF00009BE840)&$expand=Picture&$orderby=Rank desc&sf_culture=${this.$i18n.locale}`).then((response) => response.json()), fetch(`${this.$config.apiSfUrl}/dfm_irboardmembers?$expand=Picture&$orderBy=Rank&sf_culture=${this.$i18n.locale}`).then((response) => response.json()), fetch(`${this.$config.apiSfUrl}/supervisoryboardmembers?$expand=Picture&$orderBy=Order&sf_culture=${this.$i18n.locale}`).then((response) => response.json())])
      this.managements = management.value
      this.selected = management.value[0]
      this.directors = directors.value
      this.shariaboard = shariaboard.value
    } catch (err) {
      // console.log(err)
    }
  },
  methods: {
    openModal(management, type) {
      this.selected = management
      this.selectedType = type
      this.$refs.managementModal.showModal = true
    },
    stripPosition(position) {
      return position.replace(' - ', '</br>')
    },
    secureUrl(url) {
      return url.replace('http://', 'https://')
    },
  },
}
