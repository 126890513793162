
import { ScaleTransition } from 'vue2-transitions'

export default {
  components: {
    ScaleTransition,
  },
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    dropdownDirection() {
      if (this.left) {
        return this.$i18n.locale == 'en' ? 'top left' : 'top right'
      }
      return this.$i18n.locale == 'en' ? 'top right' : 'top left'
    },
  },
  watch: {
    isOpen() {
      this.$emit('toggle', this.isOpen)
    },
  },
  methods: {
    showDropdown: function () {
      this.isOpen = !this.isOpen
    },
    handleClickOutside: function () {
      if (this.isOpen) {
        if (!this.$device.isMobile) {
          this.isOpen = false
        }
      }
    },
  },
}
