
export default {
  computed: {
    hasTitle() {
      return !!this.$slots.title
    },
    hasSubtitle() {
      return !!this.$slots.subtitle
    },
    hasContent() {
      return !!this.$slots.content
    },
  },
}
