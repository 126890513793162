
import listedCreditsStatic from '~/static/data/carboncredits.json'
// import sortBy from 'lodash/sortBy'
// import orderBy from 'lodash/orderBy'
export default {
  fetchOnServer: false,
  data() {
    return {
      activeDataTimer: '',
      dataFetchInterval: 30000,
      listedCreditsStatic,
      listedCredits: [],
      mkdInfo: null,
      initialLoad: true,
    }
  },
  async fetch() {
    try {
      const mkd = await fetch('https://feeds.nasdaqdubai.com/apps/sso/source/mkdcop28')
      const mkdInfo = await fetch('https://feeds.nasdaqdubai.com/apps/sso/source/marketinfocop28')
      var mkdData = await mkd.json()
      var mkdInfoData = await mkdInfo.json()
      this.listedCredits = mkdData.root
      this.mkdInfo = mkdInfoData.root[0]
      this.initialLoad = false
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    // this is called when the component is activated
    this.activeDataTimer = setInterval(() => {
      this.$fetch()
      // // console.log('active data', this.$dayjs().format('mm:ss'))
    }, this.dataFetchInterval)
  },
  activated() {
    // this is called when the component is activated
    this.activeDataTimer = setInterval(() => {
      this.$fetch()
    }, this.dataFetchInterval)
  },

  deactivated() {
    // this is called when the component is deactivated
    // // console.log('dactivated')
    clearInterval(this.activeDataTimer)
  },
  unmounted() {
    // this is called when the component is deactivated
    // // console.log('dactivated')
    clearInterval(this.activeDataTimer)
  },


}
