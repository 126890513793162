
export default {
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
    radioTabs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [], // all of the tabs
    }
  },
  watch: {
    selectedIndex() {
      this.$emit('change', this.selectedIndex)
    },
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    this.selectTab(0)
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i
      })
    },
    onChange(event) {
      this.selectTab(Number(event.target.value))
      // // console.log(Number(event.target.value));
    },
  },
}
