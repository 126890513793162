import { render, staticRenderFns } from "./DisclosureCardSmall.vue?vue&type=template&id=8f53df82&"
import script from "./DisclosureCardSmall.vue?vue&type=script&lang=js&"
export * from "./DisclosureCardSmall.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupAttachmentButton: require('/vercel/path0/components/blocks/PopupAttachmentButton.vue').default,AttachmentListDropdown: require('/vercel/path0/components/blocks/AttachmentListDropdown.vue').default,CustomDropdown: require('/vercel/path0/components/ui/CustomDropdown.vue').default})
