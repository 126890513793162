
export default {
  name: 'CompanyPrices',
  fetchOnServer: false,

  data: () => ({
    filterDateRange: [],
    selectedDateRange: [],
    companies: [],
    companyPrices: [],
  }),
  async fetch() {
    try {
      this.loading = true
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=SearchCompanyPrices&Period=custom&FromDate=${this.filterDateRange[0]}&ToDate=${this.filterDateRange[1]}&Companies=${this.filterCompanies}&Language=${this.$i18n.locale}&type=json`,
      })
      var data = await response.json()
      this.companyPrices = data
      this.selectedDateRange = this.filterDateRange
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    filterCompanies() {
      if (!this.companies.length) return ''
      return this.companies.join(',')
    },
  },

  created() {
    this.filterDateRange = [this.$dayjs().subtract(3, 'month').format('DD/MM/YYYY'), this.$dayjs().format('DD/MM/YYYY')]
  },
  methods: {
    dateChanged(val) {
      this.filterDateRange = val
    },
    getcompanies(companies) {
      this.companies = companies
    },
    addTrendClass(value) {
      let TrendClass = value > 0 ? 'increase' : value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
    justToday(emit) {
      const start = this.$dayjs().toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneWeek(emit) {
      const start = this.$dayjs().subtract(7, 'day').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    oneMonth(emit) {
      const start = this.$dayjs().subtract(1, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    threeMonths(emit) {
      const start = this.$dayjs().subtract(3, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    sixMonths(emit) {
      const start = this.$dayjs().subtract(6, 'month').toDate()
      const end = this.$dayjs().toDate()
      const date = [start, end]
      emit(date)
    },
    notAfterToday(date) {
      return date.getTime() > Date.now()
    },
    clearSearch() {
      this.filterDateRange = []
    },
  },
}
