import { render, staticRenderFns } from "./dividend-distribution.vue?vue&type=template&id=3f93f66e&"
import script from "./dividend-distribution.vue?vue&type=script&lang=js&"
export * from "./dividend-distribution.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/dividend-distribution-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fdividend-distribution.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/dividend-distribution-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fdividend-distribution.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,CardWithImageBg: require('/vercel/path0/components/ui/CardWithImageBg.vue').default,FootNote: require('/vercel/path0/components/FootNote.vue').default,LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,BaseCard: require('/vercel/path0/components/ui/BaseCard.vue').default})
