
// https://github.com/gs-shop/vue-slick-carousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'HomePageHero',
  components: { VueSlickCarousel },
  fetchOnServer: false,
  data() {
    return {
      loaded: false,
      heroBanners: [],
      slickOptions: {
        arrows: true,
        dots: true,
        fade: false,
        autoplay: false,
        rtl: this.$i18n.locale == 'ar',
      },
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=GetFreshTopBigBinner&Language=${this.$i18n.locale}`,
      })
      var data = await response.json()
      if (this.$i18n.locale == 'ar') data.reverse()
      this.heroBanners = data
    } catch (error) {
      // console.log(error)
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true
    },
  },
}
