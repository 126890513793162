
export default {
  name: 'IvestorApplePay',
  layout: 'ivestor',
  fetchOnServer: false,
  data() {
    return {

    }
  }
}
