import { render, staticRenderFns } from "./direct-market-access.vue?vue&type=template&id=f5f7ae46&"
import script from "./direct-market-access.vue?vue&type=script&lang=js&"
export * from "./direct-market-access.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/direct-market-access-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fmembers%2Fdirect-market-access.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/direct-market-access-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fmembers%2Fdirect-market-access.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,FeaturesGridTwo: require('/vercel/path0/components/FeaturesGridTwo.vue').default,BrokersListByService: require('/vercel/path0/components/datablocks/BrokersListByService.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default,FaqsAccordion: require('/vercel/path0/components/datablocks/FaqsAccordion.vue').default})
