
export default {
  props: {
    modalSize: {
      type: Number,
      default: 1280,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    parentRoute() {
      var matchedRoutes = this.$route.matched;
      var el = matchedRoutes.filter((el) => el.parent != undefined);
      return el[0].parent.path;
    },
  },
  mounted() {
    this.showModal = true;
  },
  activated() {
    this.showModal = true;
  },
  methods: {
    modalClosed() {
      this.$router.replace(this.parentRoute);
    },
  },
};
