
export default {
  name: 'EboardOverview',
  data() {
    return {
      activeModal: 'nominationRules',
      lang: this.$i18n.locale,
      showNotificationForm: false,
      tabs: [
        {
          title: this.$t('tabs.0.title'),
          href: this.$t('tabs.0.href'),
        },
        {
          title: this.$t('tabs.1.title'),
          href: this.$t('tabs.1.href'),
        },

      ],
    }
  },
  watch: {
    $route() {
      this.updateRoute()
    },
  },
  mounted() {
    this.updateRoute()
  },
  methods: {
    openModal(modal) {
      this.activeModal = modal
      this.$refs[modal].showModal = true
    },
    openNotificationFormModal() {
      this.$refs.notificationForm.showModal = true
      this.showNotificationForm = true;
    },
    formSubmitted() {
      this.$refs.notificationForm.showModal = false
    },
    startCase(str) {
      return startCase(str)
    },
    updateRoute() {
      if (this.$route.name == this.localeRoute('the-exchange-news-disclosures-e-board').name) {
        this.$router.replace(
          this.localePath({
            name: 'the-exchange-news-disclosures-e-board-positions',
          })
        )
      }
    },
  },
}
