import { render, staticRenderFns } from "./how-to-trade.vue?vue&type=template&id=5a6a8bdf&scoped=true&"
import script from "./how-to-trade.vue?vue&type=script&lang=js&"
export * from "./how-to-trade.vue?vue&type=script&lang=js&"
import style0 from "./how-to-trade.vue?vue&type=style&index=0&id=5a6a8bdf&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6a8bdf",
  null
  
)

/* custom blocks */
import block0 from "./locale/howtotrade-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fhow-to-trade.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/howtotrade-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fhow-to-trade.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,HalfImageHalfGrid: require('/vercel/path0/components/HalfImageHalfGrid.vue').default,BaseCard: require('/vercel/path0/components/ui/BaseCard.vue').default,Decorator: require('/vercel/path0/components/ui/decorator.vue').default,VueSimpleAccordion: require('/vercel/path0/components/VueSimpleAccordion.vue').default})
