import { render, staticRenderFns } from "./indices.vue?vue&type=template&id=b2122af4&scoped=true&"
import script from "./indices.vue?vue&type=script&lang=js&"
export * from "./indices.vue?vue&type=script&lang=js&"
import style0 from "./indices.vue?vue&type=style&index=0&id=b2122af4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2122af4",
  null
  
)

/* custom blocks */
import block0 from "./indices.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default})
