
import { emit } from 'process'

export default {
  name: 'IvestorBetaForm',
  data() {
    return {
      form: {
        FormId: 'b09393f8-f602-6339-b0d9-ff00009be840',
        MarketoSettings: { syncFormFieldsToLeadFields: false, doSpecificWebCalls: false },
        WidgetId: '000d96f8-f602-6339-b0d9-ff00009be840',
        FirstName: '',
        LastName: '',
        ExistingInvestor: 'no',
        ExistingiVestorCard: 'no',
        AgeGroup: '',
        EmailTextFieldController: '',
        Phone: '',
        DeviceType: '',
        Feedback: '',
        AcceptTerms: false,
        action: 'validate_captcha',
      },
      showSuccess: false,
      showError: false,
      submittingForm: false,
    }
  },
  computed: {
    CountryOfResidence() {
      return this.$refs['country-dropdown'].selectedCountry
    },
    Nationality() {
      return this.$refs['nationality-dropdown'].selectedCountry
    },
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  async activated() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      // console.log(e)
    }
  },
  deactivated() {
    this.$recaptcha.destroy()
  },
  methods: {
    async submitForm() {
      try {
        this.submittingForm = true
        const token = await this.$recaptcha.execute('login')
        // // console.log('ReCaptcha token:', token)
        const formData = new FormData()
        formData.append('FormId', this.form.FormId), formData.append('MarketoSettings', JSON.stringify(this.form.MarketoSettings)), formData.append('WidgetId', this.form.WidgetId), formData.append('ExistingInvestor', this.form.ExistingInvestor), formData.append('ExistingiVestorCard', this.form.ExistingiVestorCard), formData.append('FirstName', this.form.FirstName), formData.append('LastName', this.form.LastName), formData.append('EmailTextFieldController', this.form.EmailTextFieldController), formData.append('CountryOfResidence', this.CountryOfResidence), formData.append('Nationality', this.Nationality), formData.append('AgeGroup', this.form.AgeGroup), formData.append('DeviceType', this.form.DeviceType), formData.append('Phone', this.form.Phone), formData.append('Feedback', this.form.Feedback), formData.append('action', this.form.action), formData.append('g-recaptcha-response', token)

        var response = await this.$axios.post('/ivestorformapi', formData)
        if (response.data.success == true) {
          this.showSuccess = true
          this.submittingForm = false
          setTimeout(() => {
            this.showSuccess = false
            this.resetForm()
            this.formSubmitted()
          }, 5000)
        } else {
          // this.showError = true
          // setTimeout(() => {
          //   this.showError = false
          //   this.resetForm()
          //   this.formSubmitted()
          // }, 5000)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    formSubmitted() {
      this.$emit('formSubmitted')
    },
    resetForm() {
      this.form.FirstName = ''
      this.form.LastName = ''
      this.form.ExistingInvestor = ''
      this.form.ExistingiVestorCard = ''
      this.form.AgeGroup = ''
      this.form.EmailTextFieldController = ''
      this.form.Phone = ''
      this.form.DeviceType = ''
      this.form.Feedback = ''
      this.form.AcceptTerms = false
      this.$recaptcha.destroy()
    },
  },
}
