
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart,
  },

  props: {
    chartData: {
      default: null,
    },

    chartHeight: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: this.chartHeight,
          // width: 800,
        },
        title: {
          style: {
            display: 'none',
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          borderRadius: 0,
          layout: 'vertical',
          align: 'center',
          style: {
            display: 'none',
            margin: [5, 0, 0, 0],
          },
          y: 0,
          useHTML: true,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
          useHTML: true,
          borderRadius: 0,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            size: 400,
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4,
              },
            },

            showInLegend: true,
            colors: ['#2563eb', '#1e40af', '#0369a1', '#0891b2', '#3b82f6', '#3730a3', '#1e3a8a', '#93c5fd', '#60a5fa', '#3b82f6', '#2563eb', '#1d4ed8', , '#1e3a8a'],
          },
        },
        series: [
          {
            type: 'pie',
            colorByPoint: true,
            name: 'Percentage',
            data: this.chartData,
          },
        ],
      },
    }
  },
  computed: {
    endDate() {
      return this.$dayjs().format('YYYY-MM-DD')
    },
    startDate() {
      return this.$dayjs().subtract(12, 'month').format('YYYY-MM-DD')
      // return "01-01-2007";
    },
  },

  mounted() {
    // this.processChartData()
  },
  methods: {
    processChartData() {
      let data = this.chartData

      // if (this.chartData) {
      //     data = this.chartData
      // } else {
      //     data = this.chartFeedData
      // }
      if (this.chartType == 'Spline') {
        let tmp = []
        data.forEach((element) => {
          tmp.push([
            Date.parse(element.date), // the date
            parseFloat(element.currentIndex), // close
          ])
        })
        this.chartOptionsSpline.series[0].data = tmp
      } else if (this.chartType == 'Minimal') {
        let tmp = []
        data.forEach((element) => {
          tmp.push([
            element.d, // the date
            element.c, // close
          ])
        })
        this.chartOptionsMinimal.series[0].data = tmp
      } else if (this.chartType == 'OHLC') {
        let ohlc = []
        let volume = []
        data.forEach((element) => {
          ohlc.push([
            element.d, // the date
            element.o, // open
            element.h, // high
            element.l, // low
            element.c, // close// close
          ])
          volume.push([
            element.d, // the date
            element.vo, // the volume
          ])
        })
        this.chartOptionsOhlc.series[0].data = ohlc
        this.chartOptionsOhlc.series[1].data = volume
      }
    },
  },
}
