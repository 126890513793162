
export default {
  computed: {
    dfmgi() {
      return this.$store.state.marketwatch.dfmgi
    },
    trendClass() {
      var trend
      if (this.dfmgi.changepercentage > 0) {
        trend = 'up'
      } else if (this.dfmgi.changepercentage < 0) {
        trend = 'down'
      } else {
        trend = 'unchanged'
      }
      return trend
    },
  },
}
