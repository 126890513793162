
export default {
  name: 'GoingPublicCta',
  props: {
    imageSrc: {
      type: String,
      default: 'visuals/image5_rso9vv.jpg',
    },
  },
}
