
export default {
  name: 'InfoBox',
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
}
