
export default {
  name: 'ServiceDetail',
  fetchOnServer: false,
  data() {
    return {
      service: null,
    }
  },
  async fetch() {
    let res = await this.$axios.get(`${this.$config.apiSfUrl}/services`, {
      params: {
        $filter: `Id eq ${this.serviceId}`,
        sf_culture: this.$i18n.locale,
      },
    })
    this.service = res.data.value[0]
  },
  computed: {
    serviceId() {
      return this.$route.params.Id
    },
  },
}
