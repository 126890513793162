
export default {
  name: 'ShariaClassificationList',
  fetchOnServer: false,
  data() {
    return {
      shariaList: [],
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=shariahlistingdetails&year=${this.$route.query.y}&quarter=${this.$route.query.q}&exchange=${this.$route.query.e}&Language=${this.$i18n.locale}`,
      })
      this.shariaList = await response.json()
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    year() {
      return this.$route.query.y
    },
    quarter() {
      return this.$route.query.q
    },
    exchange() {
      return this.$route.query.e
    },
  },
  activated() {
    this.$fetch()
  },

  methods: {
    checkEntry(arr, val) {
      // console.log(arr)
      return arr.includes(val)
    },
    convertQuarter(val) {
      if (this.$i18n.locale == 'en') {
        switch (val) {
          case '1':
            return 'Q1'
          case '2':
            return 'Q2'
          case '3':
            return 'Q3'
          case '4':
            return 'Q4'
        }
        //check current locale
      } else if (this.$i18n.locale == 'ar') {
        switch (val) {
          case '1':
            return 'الربع الأول'
          case '2':
            return 'الربع الثاني'
          case '3':
            return 'الربع الثالث'
          case '4':
            return 'الربع الرابع'
        }
      }
    },
  },
}
