
import Meta from '~/mixins/meta'
export default {
  name: 'ProductIvestor',
  mixins: [Meta],
  layout: 'ivestor',
  fetchOnServer: false,

  data() {
    return {
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
      // faqs: this.$t('faqs'),
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
}
