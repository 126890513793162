
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    TrendClass() {
      let TrendClass = this.value > 0 ? 'increase' : this.value < 0 ? 'decrease' : 'nocrease'
      return TrendClass
    },
  },
}
