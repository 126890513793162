
export default {
  name: 'HomePageHeroSingle',
  data() {
    return {
      loaded: false,
      heroBanners: [],
      slickOptions: {
        arrows: false,
        dots: true,
        fade: false,
        rtl: this.$i18n.locale == 'ar',
      },
    }
  },
}
