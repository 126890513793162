
export default {
  name: 'SingleStockFutures',
  fetchOnServer: false,
  data() {
    return {
      specifications: this.$t('specifications.specs'),
    }
  },
}
