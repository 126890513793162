
export default {
  props: {
    alignRight: {
      type: Boolean,
    },
  },
  data() {
    return {
      isListOpen: false,
      listFilterKeyword: '',
      listedCompanies: {},
      showSelectedCompanies: false,
    }
  },
  async fetch() {
    try {
      const rawResponse = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=LiteSecuritiesLists&Language=${this.$i18n.locale}`,
      })
      const content = await rawResponse.json()
      Object.values(content).forEach((val) => {
        if (Array.isArray(val)) {
          val.forEach((security) => {
            security.selected = false
            if (this.$route.query.id && security.SecuritySymbol.toLowerCase() == this.$route.query.id.toLowerCase()) {
              security.selected = true
            }
          })
        }
      })
      this.listedCompanies = content
    } catch (error) {
      // console.log(error)
    }
  },
  computed: {
    selectedCompanies() {
      let arr = []
      Object.values(this.listedCompanies).forEach((val) => {
        if (Array.isArray(val)) {
          arr.push(...val.filter((security) => security.selected).map((security) => security.SecuritySymbol))
        }
      })
      return arr
    },
    filteredCompanies() {
      if (!this.selectedCompanies.length) {
        this.showSelectedCompanies = false
      }
      let obj = {}
      Object.entries(this.listedCompanies).forEach((val) => {
        const [key, value] = val
        if (Array.isArray(value)) {
          if (this.showSelectedCompanies) {
            var tmp = value.filter((security) => {
              if (security.FullName) {
                return security.FullName.toLowerCase().includes(this.listFilterKeyword.toLowerCase()) && security.selected == this.showSelectedCompanies
              }
            })
            obj[key] = tmp
          } else {
            var tmp = value.filter((security) => {
              if (security.FullName) {
                return security.FullName.toLowerCase().includes(this.listFilterKeyword.toLowerCase())
              }
            })
            obj[key] = tmp
          }
        }
      })
      return obj
    },
  },
  watch: {
    selectedCompanies: function () {
      this.$emit('get-companies', this.selectedCompanies)
    },
  },
  mounted() {
    this.$emit('get-companies', this.selectedCompanies)
  },
  methods: {
    selectSecurity(security) {
      security.selected = !security.selected
    },
    deleteSelectedCompanies() {
      this.showSelectedCompanies = false
      Object.values(this.listedCompanies).forEach((val) => {
        if (Array.isArray(val)) {
          val.forEach((security) => {
            security.selected = false
          })
        }
      })
    },
    closeListBox() {
      this.isListOpen = false
      this.listFilterKeyword = ''
    },
  },
}
