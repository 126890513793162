
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
	name: 'VideoThumbnailCard',
	components: { vueVimeoPlayer },
	props: {
		video: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			isModalOpen: false,
			modalRef: 'video' + this._uid,
			playerRef: 'player' + this._uid,
			currentVideoId: '',
		}
	},
	methods: {
		playVideo: function (videoId) {
			this.isModalOpen = true
			this.$refs[this.modalRef].showModal = true
			setTimeout(() => {
				this.$refs[this.playerRef].play()
			}, 2000)
		},

		modalClosed() {
			this.$refs[this.playerRef].pause()
		},
	},
}
