
export default {
  data() {
    return {
      filterExchange: [this.$t('exchange-dfm'), this.$t('exchange-nasdaq')],
    }
  },
  computed: {
    exchanges() {
      return [this.$t('exchange-dfm'), this.$t('exchange-nasdaq')]
    },

    reactiveFilterExchange() {
      return this.filterExchange
    },
  },
  watch: {
    filterExchange: function () {
      this.$emit('get-exchanges', this.reactiveFilterExchange)
    },
  },
  mounted() {
    this.$emit('get-exchanges', this.reactiveFilterExchange)
  },
}
