import { render, staticRenderFns } from "./board-members-and-management.vue?vue&type=template&id=658936e0&"
import script from "./board-members-and-management.vue?vue&type=script&lang=js&"
export * from "./board-members-and-management.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locale/board-members-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fboard-members-and-management.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./locale/board-members-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Fboard-members-and-management.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,NavToScroll: require('/vercel/path0/components/NavToScroll.vue').default,ManagementCard: require('/vercel/path0/components/ManagementCard.vue').default,FloatingModal: require('/vercel/path0/components/FloatingModal.vue').default})
