
import { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon } from 'vue-simple-accordion'
// import '~/assets/css/vendor/vue-simple-accordion.css'

export default {
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
  },
  props: {
    condensed: {
      type: Boolean,
      default: false,
    },
    initActive: {
      type: Boolean,
      default: false,
    },
    autoCollapse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasSubtitleSlot() {
      return !!this.$slots.subtitle
    },
    hasContentSlot() {
      return !!this.$slots.content
    },
  },
}
