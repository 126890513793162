
import Meta from '~/mixins/meta'

export default {
  name: 'IpoAcceleratorProgramme',
  mixins: [Meta],
  data() {
    return {
      selectedTeamMember: this.$t('team.members')[0],
      showSessionsModal: true,
      meta: {
        title: this.$t('page-meta.title'),
        description: this.$t('page-meta.desc'),
        image: `${this.$config.headImage}${this.$t('page-meta.image')}`,
      },
    }
  },
  computed: {
    teamMembers() {
      return this.$t('team.members')
    },
  },
  methods: {
    openModal(member) {
      this.selectedTeamMember = member
      this.$refs.teamModal.showModal = true
    },
    openSessionsModal(member) {
      this.$refs.sessionsModal.showModal = true
    },
    stripDateMonth(date) {
      if (date != 'TBC' || 'سيتم تحديده لاحقاً') {
        return this.$dayjs(date, 'DD-MM-YYYY').format('DD MMM')
      }
      else {
        console.log("Date", date)
        return date
      }

    },
  },
}
