import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7b3ca739&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7b3ca739&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,LatestDisclosureCard: require('/vercel/path0/components/LatestDisclosureCard.vue').default,FilterAnnouncements: require('/vercel/path0/components/FilterAnnouncements.vue').default,SearchInput: require('/vercel/path0/components/SearchInput.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,DisclosureCard: require('/vercel/path0/components/DisclosureCard.vue').default,CustomPagination: require('/vercel/path0/components/ui/CustomPagination.vue').default})
